import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        appointmentInteractions: [],
    },

    getters: {},

    mutations: {
        SET_APPOINTMENT_INTERACTION(state, interaction) {
            state.appointmentInteractions.push(interaction);
        },
    },

    actions: {
        // Store appointment interactions by API call.
        async postAppointmentInteractions({ commit }, data) {
            const path = `v1/appointment-interactions`;

            try {
                const response = await axios.post(path, data);

                commit("SET_APPOINTMENT_INTERACTION", response.data.data);

                return {
                    message: "Appointment interactions created",
                    type: "success",
                    status: response.status,
                    data: response.data.data,
                };
            } catch (error) {
                return responseErrorHandler(error);
            }
        },
    },
};
