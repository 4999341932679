import axios from "axios";
import responseErrorHandler from "@/store/helper/responseErrorHandler";

export default {
    namespaced: true,

    state: {
        settingDefaultWebsiteLogoRegular: {
            id: '',
            type: 'default',
            key: 'default_website_logo_regular',
            value: '',
          },
        settingDefaultWebsiteLogoLite: {
            id: '',
            type: 'default',
            key: 'default_website_logo_lite',
            value: '',
        },
        settingDefaultWebsiteFavicon: {
            id: '',
            type: 'default',
            key: 'default_website_favicon',
            value: '',
        },
        settingDefaultCustomerAppImage: {
            id: '',
            type: 'default',
            key: 'default_customer_app_image',
            value: '',
        },
        settingDefaultWebsiteHeaderBannerSlider: {
            id: '',
            type: 'default',
            key: 'default_website_header_banner_slider',
            value: '',
        },
        settingDefaultPagination: {
            id: '',
            type: 'default',
            key: 'default_pagination',
            value: '',
        },
        settingDefaultWebsiteUrl: {
            id: '',
            type: 'default',
            key: 'default_website_url',
            value: '',
        },
        settingDefaultAdminPanelUrl: {
            id: '',
            type: 'default',
            key: 'default_admin_panel_url',
            value: '',
        },
        settingDefaultWebsiteCustomerPortalLoginUrl: {
            id: '',
            type: 'default',
            key: 'default_website_customer_portal_login_url',
            value: '',
        },
        settingDefaultWebsitePrivacyPolicyUrl: {
            id: '',
            type: 'default',
            key: 'default_website_privacy_policy_url',
            value: '',
        },
        settingDefaultWebsiteTermsAndConditionsUrl: {
            id: '',
            type: 'default',
            key: 'default_website_terms_and_conditions_url',
            value: '',
        },
        settingDefaultWebsiteAboutUsUrl: {
            id: '',
            type: 'default',
            key: 'default_website_about_us_url',
            value: '',
        },
        settingDefaultWebsiteMedicalServicePageUrl: {
            id: '',
            type: 'default',
            key: 'default_website_medical_service_page_url',
            value: '',
        },
        settingDefaultWebsiteNDISPageUrl: {
            id: '',
            type: 'default',
            key: 'default_website_ndis_page_url',
            value: '',
        },
        settingDefaultWebsiteFAQUrl: {
            id: '',
            type: 'default',
            key: 'default_website_faq_url',
            value: '',
        },
        settingDefaultCustomerCareEmailAddress: {
            id: '',
            type: 'default',
            key: 'default_customer_care_email_address',
            value: '',
        },
        settingDefaultCompanySocialLinkFacebook: {
            id: '',
            type: 'default',
            key: 'default_company_social_link_facebook',
            value: '',
        },
        settingDefaultCompanySocialLinkLinkedin: {
            id: '',
            type: 'default',
            key: 'default_company_social_link_linkedin',
            value: '',
        },
        settingDefaultCompanySocialLinkYoutube: {
            id: '',
            type: 'default',
            key: 'default_company_social_link_youtube',
            value: '',
        },
        settingDefaultCompanySocialLinkInstagram: {
            id: '',
            type: 'default',
            key: 'default_company_social_link_instagram',
            value: '',
        },
        settingDefaultContactNumber: {
            id: '',
            type: 'default',
            key: 'default_contact_number',
            value: '',
        },
        settingDefaultStateContactNumber: {
            id: '',
            type: 'default',
            key: 'default_state_contact_number',
            value: [],
        },
        settingDefaultStateTimezone: {
            id: '',
            type: 'default',
            key: 'default_state_timezone',
            value: '',
        },

        settingDefaultParkingType: {
            id: '',
            type: 'default',
            key: 'default_parking_type',
            value: [],
        },

        settingDefaultState: {
            id: '',
            type: 'default',
            key: 'default_state',
            value: [],
        },

        settingDefaultCustomerType: {
            id: '',
            type: 'default',
            key: 'default_customer_type',
            value: [],
        },

        settingDefaultCustomerRole: {
            id: '',
            type: 'default',
            key: 'default_customer_role',
            value: {},
        },

        settingServiceCodePrefix: {
            id: '',
            type: 'service',
            key: 'service_code_prefix',
            value: '',
        },

        settingServiceCodeSuffix: {
            id: '',
            type: 'service',
            key: 'service_code_suffix',
            value: '',
        },

        settingServiceStatePriceRate: {
            id: '',
            type: 'service',
            key: 'service_state_price_rate',
            value: [],
        },
        settingServiceOfferPrice: {
            id: '',
            type: 'service',
            key: 'service_offer_price',
            value: {},
        },
        settingDefaultServiceGlobalFlatPrice: {
            id: '',
            type: 'default',
            key: 'default_service_global_flat_price',
            value: {},
        },

        settingTechnicianWorkingSchedule: {
            id: '',
            type: 'technician',
            key: 'technician_working_schedule',
            value: [],
        },

        settingCustomerReferralCodePrefix: {
            id: '',
            type: 'customer',
            key: 'customer_referral_code_prefix',
            value: '',
        },

        settingCustomerReferralCodeSuffix: {
            id: '',
            type: 'customer',
            key: 'customer_referral_code_suffix',
            value: '',
        },

        settingAppointmentReferencePrefix: {
            id: '',
            type: 'appointment',
            key: 'appointment_reference_prefix',
            value: '',
        },


        settingAppointmentReferenceSuffix: {
            id: '',
            type: 'appointment',
            key: 'appointment_reference_suffix',
            value: '',
        },

        settingAppointmentStatus: {
            id: '',
            type: 'appointment',
            key: 'appointment_status',
            value: [],
        },

        settingAppointmentServicePlatform: {
            id: '',
            type: 'appointment',
            key: 'appointment_service_platform',
            value: [],
        },

        settingAppointmentNoteType: {
            id: '',
            type: 'appointment',
            key: 'appointment_note_type',
            value: [],
        },

        settingAppointmentOnlineBookingStatus: {
            id: '',
            type: 'appointment',
            key: 'appointment_online_booking_status',
            value: '',
        },

        settingAppointmentSameDayBookingStatus: {
            id: '',
            type: 'appointment',
            key: 'appointment_same_day_booking_status',
            value: [],
        },

        settingAppointmentSameDaySurcharge: {
            id: '',
            type: 'appointment',
            key: 'appointment_same_day_surcharge',
            value: [],
        },

        settingAppointmentTimebaseSurcharge: {
            id: '',
            type: 'appointment',
            key: 'appointment_timebase_surcharge',
            value: [],
        },

        settingAppointmentDistanceSurCharge: {
            id: '',
            type: 'appointment',
            key: 'appointment_distance_surcharge',
            value: [],
        },

        settingAppointmentHolidaySurcharge: {
            id: '',
            type: 'appointment',
            key: 'appointment_holiday_surcharge',
            value: [],
        },

        settingAppointmentWeekendSurcharge: {
            id: '',
            type: 'appointment',
            key: 'appointment_weekend_surcharge',
            value: [],
        },

        settingAppointmentFuelLevySurcharge: {
            id: '',
            type: 'appointment',
            key: 'appointment_fuel_levy_surcharge',
            value: '',
        },

        settingAppointmentFirstAppointmentDiscount: {
            id: '',
            type: 'appointment',
            key: 'appointment_first_appointment_discount',
            value: [],
        },
        settingDiscountParkingDiscountOverCouponDiscount: {
            id: '',
            type: 'discount',
            key: 'discount_parking_discount_over_coupon_discount',
            value: [],
        },
        settingDiscountOnlineAppointmentDiscountOverCouponDiscount: {
            id: '',
            type: 'discount',
            key: 'discount_online_appointment_discount_over_coupon_discount',
            value: [],
        },
        settingCouponWeekendDays: {
            id: '',
            type: 'coupon',
            key: 'coupon_weekend_days',
            value: [],
        },

        settingAppointmentCancellationPolicyByCustomer: {
            id: '',
            type: 'appointment',
            key: 'appointment_cancellation_by_customer_policy',
            value: [],
        },

        settingAppointmentRefundPolicy: {
            id: '',
            type: 'appointment',
            key: 'appointment_refund_policy',
            value: [],
        },

        settingAppointmentCreditedPolicy: {
            id: '',
            type: 'appointment',
            key: 'appointment_credited_policy',
            value: [],
        },
        settingAppointmentReschedulePolicy: {
            id: '',
            type: 'appointment',
            key: 'appointment_reschedule_by_customer_policy',
            value: [],
        },

        settingOrderOrderNumberPrefix: {
            id: '',
            type: 'order',
            key: 'order_order_number_prefix',
            value: '',
        },

        settingOrderOrderNumberSuffix: {
            id: '',
            type: 'order',
            key: 'order_order_number_suffix',
            value: '',
        },
        settingOrderDeliveryCharge: {
            id: '',
            type: 'order',
            key: 'order_delivery_charge',
            value: '',
        },
        settingOrderRegisteredAddresses: {
            id: '',
            type: 'order',
            key: 'order_registered_addresses',
            value: '',
        },
        settingOrderOnSiteMaximumDistance: {
            id: '',
            type: 'order',
            key: 'order_on_site_maximum_distance',
            value: '',
        },
        settingOrderCreationEmailNotifyForSystem: {
            id: '',
            type: 'order',
            key: 'order_creation_email_notify_for_system',
            value: '',
        },

        settingOrderCreationEmailNotifyForCustomer: {
            id: '',
            type: 'order',
            key: 'order_creation_email_notify_for_customer',
            value: '',
        },

        settingOrderStatusUpdateEmailNotifyForSystem: {
            id: '',
            type: 'order',
            key: 'order_status_update_email_notify_for_system',
            value: '',
        },
        settingOrderStatusUpdateEmailNotifyForCustomer: {
            id: '',
            type: 'order',
            key: 'order_status_update_email_notify_for_customer',
            value: '',
        },

        settingProductProductCodePrefix: {
            id: '',
            type: 'product',
            key: 'product_code_prefix',
            value: '',
        },

        settingProductProductCodeSuffix: {
            id: '',
            type: 'product',
            key: 'product_code_suffix',
            value: '',
        },

        settingProductItemCondition: {
            id: '',
            type: 'product_item',
            key: 'product_item_condition',
            value: [],
        },

        settingAppointmentServiceDuration: {
            id: '',
            type: 'appointment',
            key: 'appointment_service_duration',
            value: '',
        },

        settingAppointmentOnSiteMaximumDistance: {
            id: '',
            type: 'appointment',
            key: 'appointment_on_site_maximum_distance',
            value: '',
        },

        settingAppointmentParkingDiscount: {
            id: '',
            type: 'appointment',
            key: 'appointment_parking_discount',
            value: '',
        },

        settingAppointmentRegisteredAddresses: {
            id: '',
            type: 'appointment',
            key: 'appointment_registered_addresses',
            value: [],
        },
      
        settingAppointmentCreationEmailNotifyForSystem: {
            id: '',
            type: 'appointment',
            key: 'appointment_creation_email_notify_for_system',
            value: [],
        },
        settingAppointmentCreationEmailNotifyForTechnician: {
            id: '',
            type: 'appointment',
            key: 'appointment_creation_email_notify_for_technician',
            value: [],
        },
        settingAppointmentCreationEmailNotifyForCustomer: {
            id: '',
            type: 'appointment',
            key: 'appointment_creation_email_notify_for_customer',
            value: [],
        },
        
        settingAppointmentStatusUpdateEmailNotifyForTechnician: {
            id: '',
            type: 'appointment',
            key: 'appointment_status_update_email_notify_for_technician',
            value: '',
        },
        settingAppointmentStatusUpdateEmailNotifyForCustomer: {
            id: '',
            type: 'appointment',
            key: 'appointment_status_update_email_notify_for_customer',
            value: '',
        },

        settingAppointmentOnlineAppointmentDiscount: {
            id: '',
            type: 'appointment',
            key: 'appointment_online_appointment_discount',
            value: [],
        },

        settingAppointmentOnlineAppointmentWithoutPaymentStatus: {
            id: '',
            type: 'appointment',
            key: 'appointment_online_appointment_without_payment_status',
            value: [],
        },
        settingAppointmentOnlineAppointmentWithPartialPaymentStatus: {
            id: '',
            type: 'appointment',
            key: 'appointment_online_appointment_with_partial_payment_status',
            value: "0",

        },
        settingAppointmentOnlineAppointmentWithUndecidedStatus: {
            id: '',
            type: 'appointment',
            key: 'appointment_online_appointment_with_undecided_status',
            value: 0,

        },
       settingAppointmentServiceExtraDurationUnitAndChargeRate: {
            id: '',
            type: 'appointment',
            key: 'appointment_service_extra_duration_unit_and_charge_rate',
            value: [],
        },

        settingLeaveApplicationType: {
            id: '',
            type: 'leave_application',
            key: 'leave_application_type',
            value: [],
        },
        settingLeaveApplicationStatus: {
            id: '',
            type: 'leave_application',
            key: 'leave_application_status',
            value: [],
        },
        settingDefaultGstRate: {
            id: '',
            type: 'default',
            key: 'default_gst_rate',
            value: [],
        },
        settingOrderStatus: {
            id: '',
            type: 'order',
            key: 'order_status',
            value: [],
        },
        settingOrderNoteType: {
            id: '',
            type: 'order',
            key: 'order_note_type',
            value: [],
        },
        settingRefundNoteType: {
            id: '',
            type: 'refund',
            key: 'refund_note_type',
            value: [],
        },
        settingDefaultCompanyName: {
            id: '',
            type: 'default',
            key: 'default_company_name',
            value: '',
        },
        settingDefaultCompanyABN: {
            id: '',
            type: 'default',
            key: 'default_company_abn',
            value: '',
        },
        settingDefaultCompanyAddress: {
            id: '',
            type: 'default',
            key: 'default_company_address',
            value: '',
        },
        settingDefaultStateCompanyAddress: {
            id: '',
            type: 'default',
            key: 'default_state_company_address',
            value: [],
        },
        settingVacancyApplyEmailAddress: {
            id: '',
            type: 'vacancy',
            key: 'vacancy_apply_email_address',
            value: '',
        },

        //Product code settings
        settingProductCodePrefix: {
            id: '',
            type: 'product',
            key: 'product_code_prefix',
            value: '',
        },

        settingProductCodeSuffix: {
            id: '',
            type: 'product',
            key: 'product_code_suffix',
            value: '',
        },

        //Contact settings
        settingContactReferencePrefix: {
            id: '',
            type: 'contact',
            key: 'contact_reference_prefix',
            value: ''
        },
        settingContactReferenceSuffix: {
            id: '',
            type: 'contact',
            key: 'contact_reference_suffix',
            value: ''
        },
        settingContactRequestForList: {
            id: '',
            type: 'contact',
            key: 'contact_request_for_list',
            value: ''
        },

        //Call Request Reference settings
        settingCallRequestReferencePrefix: {
            id: '',
            type: 'call_request',
            key: 'call_request_reference_prefix',
            value: ''
        },
        settingCallRequestReferenceSuffix: {
            id: '',
            type: 'call_request',
            key: 'call_request_reference_suffix',
            value: ''
        },

        //Technician commission payment settings
        settingTechnicianCommissionPaymentReferencePrefix: {
            id: '',
            type: 'technician_commission_payment',
            key: 'technician_commission_payment_reference_prefix',
            value: ''
        },
        settingTechnicianCommissionPaymentReferenceSuffix: {
            id: '',
            type: 'technician_commission_payment',
            key: 'technician_commission_payment_reference_suffix',
            value: ''
        },

        settingPaymentCodePrefix: {
            id: '',
            type: 'payment',
            key: 'payment_code_prefix',
            value: '',
        },

        settingPaymentCodeSuffix: {
            id: '',
            type: 'payment',
            key: 'payment_code_suffix',
            value: '',
        },
        settingPaymentCardSurcharge: {
            id: '',
            type: 'payment',
            key: 'payment_card_surcharge',
            value: [],
        },
        //Google map settings
        settingGoogleMapDistanceMatrixApiKey: {
            id: '',
            type: 'google_map',
            key: 'google_map_distance_matrix_api_key',
            value: '',
        },
        settingGoogleMapDistanceMatrixApiEndpoint: {
            id: '',
            type: 'google_map',
            key: 'google_map_distance_matrix_api_endpoint',
            value: '',
        },
        settingVendorCodePrefix: {
            id: '',
            type: 'vendor',
            key: 'vendor_code_prefix',
            value: '',
        },
        settingVendorCodeSuffix: {
            id: '',
            type: 'vendor',
            key: 'vendor_code_suffix',
            value: '',
        },
        settingFranchiseeCodePrefix: {
            id: '',
            type: 'franchisee',
            key: 'franchisee_code_prefix',
            value: '',
        },
        settingFranchiseeCodeSuffix: {
            id: '',
            type: 'franchisee',
            key: 'franchisee_code_suffix',
            value: '',
        },
        settingFranchiseePaymentReferencePrefix: {
            id: '',
            type: 'franchisee_payment',
            key: 'franchisee_payment_reference_prefix',
            value: '',
        },
        settingFranchiseePaymentReferenceSuffix: {
            id: '',
            type: 'franchisee_payment',
            key: 'franchisee_payment_reference_suffix',
            value: '',
        },
        settingDefaultTechnicianMobApp: {
            id: '',
            type: 'default',
            key: 'default_technician_mob_app',
            value: '',
        },
        settingDefaultCustomerMobApp: {
            id: '',
            type: 'default',
            key: 'default_customer_mob_app',
            value: '',
        },
        settingDefaultInternalEmailHeader: {
            id: '',
            type: 'default',
            key: 'default_internal_email_header',
            value: '',
            description: ""
        },
        settingDefaultInternalEmailFooter: {
            id: '',
            type: 'default',
            key: 'default_internal_email_footer',
            value: '',
            description: ""
        },
        settingOrderJobStatus: {
            id: '',
            type: 'order',
            key: 'order_job_status',
            value: [],
        },
        settingPaymentSimplifyGateway3DSStatus: {
            id: '',
            type: 'payment',
            key: 'payment_simplify_gateway_3ds_status',
            value: [],
        },
        settingDefaultSmsTemplateList: {
            id: '',
            type: 'default',
            key: 'default_sms_template_list',
            value: ''
        },
        settingDefaultAppointmentPaymentCollectionStageTemplate: {
            id: '',
            type: 'default',
            key: 'default_appointment_payment_collection_stage_template',
            value: ''
        },
    },

    getters: {
        settingDefaultWebsiteLogoRegular(state) {
            return state.settingDefaultWebsiteLogoRegular;
        },
        settingDefaultWebsiteLogoLite(state) {
            return state.settingDefaultWebsiteLogoLite;
        },
        settingDefaultWebsiteFavicon(state) {
            return state.settingDefaultWebsiteFavicon;
        },
        settingDefaultCustomerAppImage(state) {
            return state.settingDefaultCustomerAppImage;
        },
        settingDefaultWebsiteHeaderBannerSlider(state) {
            return state.settingDefaultWebsiteHeaderBannerSlider;
        },
        settingDefaultPagination (state)
        {
            return state.settingDefaultPagination;
        },
        settingDefaultWebsiteUrl (state)
        {
            return state.settingDefaultWebsiteUrl;
        },
        settingDefaultAdminPanelUrl (state)
        {
            return state.settingDefaultAdminPanelUrl;
        },
        settingDefaultWebsiteCustomerPortalLoginUrl (state)
        {
            return state.settingDefaultWebsiteCustomerPortalLoginUrl;
        },
        settingDefaultWebsitePrivacyPolicyUrl (state)
        {
            return state.settingDefaultWebsitePrivacyPolicyUrl;
        },
        settingDefaultWebsiteTermsAndConditionsUrl (state)
        {
            return state.settingDefaultWebsiteTermsAndConditionsUrl;
        },
        settingDefaultWebsiteAboutUsUrl (state)
        {
            return state.settingDefaultWebsiteAboutUsUrl;
        },
        settingDefaultWebsiteMedicalServicePageUrl (state)
        {
            return state.settingDefaultWebsiteMedicalServicePageUrl;
        },
        settingDefaultWebsiteNDISPageUrl (state)
        {
            return state.settingDefaultWebsiteNDISPageUrl;
        },
        settingDefaultWebsiteFAQUrl (state)
        {
            return state.settingDefaultWebsiteFAQUrl;
        },
        settingDefaultCustomerCareEmailAddress (state)
        {
            return state.settingDefaultCustomerCareEmailAddress;
        },
        settingDefaultCompanySocialLinkFacebook (state)
        {
            return state.settingDefaultCompanySocialLinkFacebook;
        },
        settingDefaultCompanySocialLinkLinkedin (state)
        {
            return state.settingDefaultCompanySocialLinkLinkedin;
        },
        settingDefaultCompanySocialLinkYoutube (state)
        {
            return state.settingDefaultCompanySocialLinkYoutube;
        },
        settingDefaultCompanySocialLinkInstagram (state)
        {
            return state.settingDefaultCompanySocialLinkInstagram;
        },
        settingDefaultContactNumber (state)
        {
            return state.settingDefaultContactNumber;
        },
        settingDefaultStateContactNumber (state)
        {
            return state.settingDefaultStateContactNumber;
        },
        settingDefaultStateTimezone (state)
        {
            return state.settingDefaultStateTimezone;
        },

        settingDefaultParkingType (state)
        {
            return state.settingDefaultParkingType;
        },

        settingDefaultState (state)
        {
            return state.settingDefaultState;
        },

        settingDefaultCustomerType (state)
        {
            return state.settingDefaultCustomerType;
        },

        settingDefaultCustomerRole (state)
        {
            return state.settingDefaultCustomerRole;
        },

        settingServiceCodePrefix (state)
        {
            return state.settingServiceCodePrefix;
        },

        settingServiceCodeSuffix (state)
        {
            return state.settingServiceCodeSuffix;
        },

        settingServiceStatePriceRate (state)
        {
            return state.settingServiceStatePriceRate;
        },
        settingServiceOfferPrice (state)
        {
            return state.settingServiceOfferPrice;
        },
        settingDefaultServiceGlobalFlatPrice (state)
        {
            return state.settingDefaultServiceGlobalFlatPrice;
        },

        settingTechnicianWorkingSchedule (state)
        {
            return state.settingTechnicianWorkingSchedule;
        },


        settingCustomerReferralCodePrefix (state)
        {
            return state.settingCustomerReferralCodePrefix;
        },

        settingCustomerReferralCodeSuffix (state)
        {
            return state.settingCustomerReferralCodeSuffix;
        },

        settingAppointmentReferencePrefix (state)
        {
            return state.settingAppointmentReferencePrefix;
        },

        settingAppointmentReferenceSuffix (state)
        {
            return state.settingAppointmentReferenceSuffix;
        },

        settingAppointmentStatus (state)
        {
            return state.settingAppointmentStatus;
        },

        settingAppointmentServicePlatform (state)
        {
            return state.settingAppointmentServicePlatform;
        },

        settingAppointmentNoteType (state)
        {
            return state.settingAppointmentNoteType;
        },

        settingAppointmentOnlineBookingStatus (state)
        {
            return state.settingAppointmentOnlineBookingStatus;
        },

        settingAppointmentSameDayBookingStatus (state)
        {
            return state.settingAppointmentSameDayBookingStatus;
        },

        settingAppointmentSameDaySurcharge (state)
        {
            return state.settingAppointmentSameDaySurcharge;
        },

        settingAppointmentTimebaseSurcharge (state)
        {
            return state.settingAppointmentTimebaseSurcharge;
        },

        settingAppointmentDistanceSurCharge (state)
        {
            return state.settingAppointmentDistanceSurCharge;
        },

        settingAppointmentHolidaySurcharge (state)
        {
            return state.settingAppointmentHolidaySurcharge;
        },

        settingAppointmentWeekendSurcharge (state)
        {
            return state.settingAppointmentWeekendSurcharge;
        },

        settingAppointmentFuelLevySurcharge (state)
        {
            return state.settingAppointmentFuelLevySurcharge;
        },

        settingAppointmentFirstAppointmentDiscount (state)
        {
            return state.settingAppointmentFirstAppointmentDiscount;
        },
        settingDiscountParkingDiscountOverCouponDiscount (state)
        {
            return state.settingDiscountParkingDiscountOverCouponDiscount;
        },
        settingDiscountOnlineAppointmentDiscountOverCouponDiscount (state)
        {
            return state.settingDiscountOnlineAppointmentDiscountOverCouponDiscount;
        },
        settingCouponWeekendDays (state)
        {
            return state.settingCouponWeekendDays;
        },
        settingAppointmentCreationEmailNotifyForSystem (state)
        {
            return state.settingAppointmentCreationEmailNotifyForSystem;
        },
        settingAppointmentCreationEmailNotifyForTechnician (state)
        {
            return state.settingAppointmentCreationEmailNotifyForTechnician;
        },
        settingAppointmentCreationEmailNotifyForCustomer (state)
        {
            return state.settingAppointmentCreationEmailNotifyForCustomer;
        },
        settingAppointmentStatusUpdateEmailNotifyForTechnician (state)
        {
            return state.settingAppointmentStatusUpdateEmailNotifyForTechnician;
        },
        settingAppointmentStatusUpdateEmailNotifyForCustomer (state)
        {
            return state.settingAppointmentStatusUpdateEmailNotifyForCustomer;
        },
        settingAppointmentOnlineAppointmentDiscount (state)
        {
            return state.settingAppointmentOnlineAppointmentDiscount;
        },

        settingAppointmentOnlineAppointmentWithoutPaymentStatus (state)
        {
            return state.settingAppointmentOnlineAppointmentWithoutPaymentStatus;
        },

        settingAppointmentOnlineAppointmentWithPartialPaymentStatus(state) {
            return state.settingAppointmentOnlineAppointmentWithPartialPaymentStatus;
        },
        settingAppointmentOnlineAppointmentWithUndecidedStatus(state) {
            return state.settingAppointmentOnlineAppointmentWithUndecidedStatus;
        },

        settingAppointmentServiceExtraDurationUnitAndChargeRate (state)
        {
            return state.settingAppointmentServiceExtraDurationUnitAndChargeRate;
        },

        settingAppointmentCancellationPolicyByCustomer (state)
        {
            return state.settingAppointmentCancellationPolicyByCustomer;
        },

        settingAppointmentRefundPolicy (state)
        {
            return state.settingAppointmentRefundPolicy;
        },

        settingAppointmentCreditedPolicy (state)
        {
            return state.settingAppointmentCreditedPolicy;
        },

        settingAppointmentReschedulePolicy (state)
        {
            return state.settingAppointmentReschedulePolicy;
        },

        settingOrderOrderNumberPrefix (state)
        {
            return state.settingOrderOrderNumberPrefix;
        },

        settingOrderOrderNumberSuffix (state)
        {
            return state.settingOrderOrderNumberSuffix;
        },
        settingOrderDeliveryCharge (state)
        {
            return state.settingOrderDeliveryCharge;
        },

        settingOrderRegisteredAddresses (state)
        {
            return state.settingOrderRegisteredAddresses;
        },
        settingOrderOnSiteMaximumDistance (state)
        {
            return state.settingOrderOnSiteMaximumDistance;
        },
        
        settingOrderCreationEmailNotifyForSystem (state)
        {
            return state.settingOrderCreationEmailNotifyForSystem;
        },
        settingOrderCreationEmailNotifyForCustomer (state)
        {
            return state.settingOrderCreationEmailNotifyForCustomer;
        },
        settingOrderStatusUpdateEmailNotifyForSystem (state)
        {
            return state.settingOrderStatusUpdateEmailNotifyForSystem;
        },
        settingOrderStatusUpdateEmailNotifyForCustomer (state)
        {
            return state.settingOrderStatusUpdateEmailNotifyForCustomer;
        },

        settingProductProductCodePrefix (state)
        {
            return state.settingProductProductCodePrefix;
        },

        settingProductProductCodeSuffix (state)
        {
            return state.settingProductProductCodeSuffix;
        },

        settingProductItemCondition (state)
        {
            return state.settingProductItemCondition;
        },

        settingAppointmentServiceDuration (state)
        {
            return state.settingAppointmentServiceDuration;
        },

        settingAppointmentOnSiteMaximumDistance (state)
        {
            return state.settingAppointmentOnSiteMaximumDistance;
        },
        settingAppointmentParkingDiscount (state)
        {
            return state.settingAppointmentParkingDiscount;
        },

        settingAppointmentRegisteredAddresses (state)
        {
            return state.settingAppointmentRegisteredAddresses;
        },

        settingGoogleMapDistanceMatrixApiKey (state)
        {
            return state.settingGoogleMapDistanceMatrixApiKey;
        },

        settingGoogleMapDistanceMatrixApiEndpoint (state)
        {
            return state.settingGoogleMapDistanceMatrixApiEndpoint;
        },

        settingLeaveApplicationType (state)
        {
            return state.settingLeaveApplicationType;
        },

        settingDefaultGstRate (state)
        {
            return state.settingDefaultGstRate;
        },
        settingOrderStatus (state)
        {
            return state.settingOrderStatus;
        },
        settingOrderNoteType(state) {
            return state.settingOrderNoteType;
        },
        settingRefundNoteType(state) {
            return state.settingRefundNoteType;
        },
        settingDefaultCompanyName (state)
        {
            return state.settingDefaultCompanyName;
        },
        settingDefaultCompanyABN (state)
        {
            return state.settingDefaultCompanyABN;
        },
        settingDefaultCompanyAddress (state)
        {
            return state.settingDefaultCompanyAddress;
        },
        settingDefaultStateCompanyAddress (state)
        {
            return state.settingDefaultStateCompanyAddress;
        },
        settingVacancyApplyEmailAddress(state) {
            return state.settingVacancyApplyEmailAddress;
        },

        //product settings
        settingProductCodePrefix (state)
        {
            return state.settingProductCodePrefix;
        },

        settingProductCodeSuffix (state)
        {
            return state.settingProductCodeSuffix;
        },

        //contact settings
        settingContactReferencePrefix(state)
        {
            return state.settingContactReferencePrefix;
        },

        settingContactReferenceSuffix(state)
        {
            return state.settingContactReferenceSuffix;
        },

        settingContactRequestForList(state)
        {
            return state.settingContactRequestForList;
        },

        //call request settings
        settingCallRequestReferencePrefix(state)
        {
            return state.settingCallRequestReferencePrefix;
        },
        settingCallRequestReferenceSuffix(state)
        {
            return state.settingCallRequestReferenceSuffix;
        },

        settingTechnicianCommissionPaymentReferencePrefix(state)
        {
            return state.settingTechnicianCommissionPaymentReferencePrefix;
        },

        settingTechnicianCommissionPaymentReferenceSuffix(state)
        {
            return state.settingTechnicianCommissionPaymentReferenceSuffix;
        },

        settingPaymentCodePrefix (state)
        {
            return state.settingPaymentCodePrefix;
        },

        settingPaymentCodeSuffix (state)
        {
            return state.settingPaymentCodeSuffix;
        },

        settingPaymentCardSurcharge (state)
        {
            return state.settingPaymentCardSurcharge;
        },
        settingVendorCodePrefix (state)
        {
            return state.settingVendorCodePrefix;
        },
        settingVendorCodeSuffix (state)
        {
            return state.settingVendorCodeSuffix;
        },
        settingFranchiseeCodePrefix (state)
        {
            return state.settingFranchiseeCodePrefix;
        },

        settingFranchiseeCodeSuffix (state)
        {
            return state.settingFranchiseeCodeSuffix;
        },
        settingFranchiseePaymentReferencePrefix (state)
        {
            return state.settingFranchiseePaymentReferencePrefix;
        },

        settingFranchiseePaymentReferenceSuffix (state)
        {
            return state.settingFranchiseePaymentReferenceSuffix;
        },
        settingDefaultTechnicianMobApp (state)
        {
            return state.settingDefaultTechnicianMobApp;
        },
        settingDefaultCustomerMobApp (state)
        {
            return state.settingDefaultCustomerMobApp;
        },
        settingDefaultInternalEmailHeader (state)
        {
            return state.settingDefaultInternalEmailHeader;
        },
        settingDefaultInternalEmailFooter (state)
        {
            return state.settingDefaultInternalEmailFooter;
        },
        settingOrderJobStatus (state)
        {
            return state.settingOrderJobStatus;
        },
        settingPaymentSimplifyGateway3DSStatus (state)
        {
            return state.settingPaymentSimplifyGateway3DSStatus;
        },
        settingDefaultSmsTemplateList(state)
        {
            return state.settingDefaultSmsTemplateList;
        },
        settingDefaultAppointmentPaymentCollectionStageTemplate(state)
        {
            return state.settingDefaultAppointmentPaymentCollectionStageTemplate;
        },
    },

    mutations: {
        SET_SETTING_DEFAULT_WEBSITE_LOGO_REGULAR(state, setting) {
            state.settingDefaultWebsiteLogoRegular.value = setting.value;
            state.settingDefaultWebsiteLogoRegular.id = setting.id;
          },
        SET_SETTING_DEFAULT_WEBSITE_LOGO_LITE(state, setting) {
            state.settingDefaultWebsiteLogoLite.value = setting.value;
            state.settingDefaultWebsiteLogoLite.id = setting.id;
          },
        SET_SETTING_DEFAULT_WEBSITE_FAVICON(state, setting) {
            state.settingDefaultWebsiteFavicon.value = setting.value;
            state.settingDefaultWebsiteFavicon.id = setting.id;
        },
        SET_SETTING_DEFAULT_CUSTOMER_APP_IMAGE(state, setting) {
            state.settingDefaultCustomerAppImage.value = setting.value;
            state.settingDefaultCustomerAppImage.id = setting.id;
        },
        SET_SETTING_DEFAULT_WEBSITE_HEADER_BANNER_SLIDER(state, setting) {
            state.settingDefaultWebsiteHeaderBannerSlider.value = setting.value;
            state.settingDefaultWebsiteHeaderBannerSlider.id = setting.id;
        },
        SET_SETTING_DEFAULT_PAGINATION (state, setting)
        {
            state.settingDefaultPagination.value = setting.value;
            state.settingDefaultPagination.id = setting.id;
        },
        SET_SETTING_DEFAULT_WEBSITE_URL (state, setting)
        {
            state.settingDefaultWebsiteUrl.value = setting.value;
            state.settingDefaultWebsiteUrl.id = setting.id;
        },
        SET_SETTING_DEFAULT_WEBSITE_CUSTOMER_PORTAL_LOGIN_URL (state, setting)
        {
            state.settingDefaultWebsiteCustomerPortalLoginUrl.value = setting.value;
            state.settingDefaultWebsiteCustomerPortalLoginUrl.id = setting.id;
        },
        SET_SETTING_DEFAULT_WEBSITE_PRIVACY_POLICY_URL (state, setting)
        {
            state.settingDefaultWebsitePrivacyPolicyUrl.value = setting.value;
            state.settingDefaultWebsitePrivacyPolicyUrl.id = setting.id;
        },
        SET_SETTING_DEFAULT_WEBSITE_TERMS_AND_CONDITIONS_URL (state, setting)
        {
            state.settingDefaultWebsiteTermsAndConditionsUrl.value = setting.value;
            state.settingDefaultWebsiteTermsAndConditionsUrl.id = setting.id;
        },
        SET_SETTING_DEFAULT_ADMIN_PANEL_URL (state, setting)
        {
            state.settingDefaultAdminPanelUrl.value = setting.value;
            state.settingDefaultAdminPanelUrl.id = setting.id;
        },
        SET_SETTING_DEFAULT_WEBSITE_ABOUT_US_URL (state, setting)
        {
            state.settingDefaultWebsiteAboutUsUrl.value = setting.value;
            state.settingDefaultWebsiteAboutUsUrl.id = setting.id;
        },
        SET_SETTING_DEFAULT_WEBSITE_NDIS_PAGE_URL (state, setting)
        {
            state.settingDefaultWebsiteNDISPageUrl.value = setting.value;
            state.settingDefaultWebsiteNDISPageUrl.id = setting.id;
        },
        SET_SETTING_DEFAULT_WEBSITE_FAQ_URL (state, setting)
        {
            state.settingDefaultWebsiteFAQUrl.value = setting.value;
            state.settingDefaultWebsiteFAQUrl.id = setting.id;
        },
        SET_SETTING_DEFAULT_WEBSITE_MEDICAL_SERVICE_PAGE_URL (state, setting)
        {
            state.settingDefaultWebsiteMedicalServicePageUrl.value = setting.value;
            state.settingDefaultWebsiteMedicalServicePageUrl.id = setting.id;
        },
        SET_SETTING_DEFAULT_CUSTOMER_CARE_EMAIL_ADDRESS (state, setting)
        {
            state.settingDefaultCustomerCareEmailAddress.value = setting.value;
            state.settingDefaultCustomerCareEmailAddress.id = setting.id;
        },
        SET_SETTING_DEFAULT_COMPANY_SOCIAL_LINK_LINKEDIN (state, setting)
        {
            state.settingDefaultCompanySocialLinkLinkedin.value = setting.value;
            state.settingDefaultCompanySocialLinkLinkedin.id = setting.id;
        },
        SET_SETTING_DEFAULT_COMPANY_SOCIAL_LINK_YOUTUBE (state, setting)
        {
            state.settingDefaultCompanySocialLinkYoutube.value = setting.value;
            state.settingDefaultCompanySocialLinkYoutube.id = setting.id;
        },
        SET_SETTING_DEFAULT_COMPANY_SOCIAL_LINK_INSTAGRAM (state, setting)
        {
            state.settingDefaultCompanySocialLinkInstagram.value = setting.value;
            state.settingDefaultCompanySocialLinkInstagram.id = setting.id;
        },
        SET_SETTING_DEFAULT_COMPANY_SOCIAL_LINK_FACEBOOK (state, setting)
        {
            state.settingDefaultCompanySocialLinkFacebook.value = setting.value;
            state.settingDefaultCompanySocialLinkFacebook.id = setting.id;
        },


        // settingDefaultContactNumber
        SET_SETTING_DEFAULT_CONTACT_NUMBER (state, setting)
        {
            state.settingDefaultContactNumber.value = setting.value;
            state.settingDefaultContactNumber.id = setting.id;
        },
        SET_SETTING_DEFAULT_STATE_CONTACT_NUMBER (state, setting)
        {
            state.settingDefaultStateContactNumber.value = setting.value;
            state.settingDefaultStateContactNumber.id = setting.id;
        },
        SET_SETTING_DEFAULT_STATE_TIMEZONE (state, setting)
        {
            state.settingDefaultStateTimezone.id = setting.id;
            state.settingDefaultStateTimezone.value = setting.value;
        },

        SET_SETTING_DEFAULT_PARKING_TYPE (state, setting)
        {
            state.settingDefaultParkingType.id = setting.id;
            state.settingDefaultParkingType.value = setting.value;
        },

        SET_SETTING_DEFAULT_STATE (state, setting)
        {
            state.settingDefaultState.id = setting.id;
            state.settingDefaultState.value = setting.value;
        },

        SET_SETTING_DEFAULT_CUSTOMER_TYPE (state, setting)
        {
            state.settingDefaultCustomerType.id = setting.id;
            state.settingDefaultCustomerType.value = setting.value;
        },

        SET_SETTING_DEFAULT_CUSTOMER_ROLE (state, setting)
        {
            state.settingDefaultCustomerRole.id = setting.id;
            state.settingDefaultCustomerRole.value = setting.value;
        },

        SET_SETTING_SERVICE_CODE_PREFIX (state, setting)
        {
            state.settingServiceCodePrefix.id = setting.id;
            state.settingServiceCodePrefix.value = setting.value;
        },

        SET_SETTING_SERVICE_CODE_SUFFIX (state, setting)
        {
            state.settingServiceCodeSuffix.id = setting.id;
            state.settingServiceCodeSuffix.value = setting.value;
        },

        SET_SETTING_SERVICE_STATE_PRICE_RATE (state, setting)
        {
            state.settingServiceStatePriceRate.id = setting.id;
            state.settingServiceStatePriceRate.value = setting.value;
        },
        SET_SETTING_SERVICE_OFFER_PRICE (state, setting)
        {
            state.settingServiceOfferPrice.id = setting.id;
            state.settingServiceOfferPrice.value = setting.value;
        },
        SET_SETTING_DEFAULT_SERVICE_GLOBAL_FLAT_PRICE (state, setting)
        {
            state.settingDefaultServiceGlobalFlatPrice.id = setting.id;
            state.settingDefaultServiceGlobalFlatPrice.value = setting.value;
        },

        SET_SETTING_TECHNICIAN_WORKING_SCHEDULE (state, setting)
        {
            state.settingTechnicianWorkingSchedule.id = setting.id;
            state.settingTechnicianWorkingSchedule.value = setting.value;
        },

        SET_SETTING_CUSTOMER_REFERRAL_CODE_PREFIX (state, setting)
        {
            state.settingCustomerReferralCodePrefix.id = setting.id;
            state.settingCustomerReferralCodePrefix.value = setting.value;
        },

        SET_SETTING_CUSTOMER_REFERRAL_CODE_SUFFIX (state, setting)
        {
            state.settingCustomerReferralCodeSuffix.id = setting.id;
            state.settingCustomerReferralCodeSuffix.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_REFERENCE_PREFIX (state, setting)
        {
            state.settingAppointmentReferencePrefix.id = setting.id;
            state.settingAppointmentReferencePrefix.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_REFERENCE_SUFFIX (state, setting)
        {
            state.settingAppointmentReferenceSuffix.id = setting.id;
            state.settingAppointmentReferenceSuffix.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_STATUS (state, setting)
        {
            state.settingAppointmentStatus.id = setting.id;
            state.settingAppointmentStatus.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_SERVICE_PLATFORM (state, setting)
        {
            state.settingAppointmentServicePlatform.id = setting.id;
            state.settingAppointmentServicePlatform.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_NOTE_TYPE (state, setting)
        {
            state.settingAppointmentNoteType.id = setting.id;
            state.settingAppointmentNoteType.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_ONLINE_BOOKING_STATUS (state, setting)
        {
            state.settingAppointmentOnlineBookingStatus.id = setting.id;
            state.settingAppointmentOnlineBookingStatus.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_SAME_DAY_BOOKING_STATUS (state, setting)
        {
            state.settingAppointmentSameDayBookingStatus.id = setting.id;
            state.settingAppointmentSameDayBookingStatus.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_SAME_DAY_SURCHARGE (state, setting)
        {
            state.settingAppointmentSameDaySurcharge.id = setting.id;
            state.settingAppointmentSameDaySurcharge.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_TIMEBASE_SURCHARGE (state, setting)
        {

            state.settingAppointmentTimebaseSurcharge.id = setting.id;
            state.settingAppointmentTimebaseSurcharge.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_DISTANCE_SURCHARGE (state, setting)
        {
            state.settingAppointmentDistanceSurCharge.id = setting.id;
            state.settingAppointmentDistanceSurCharge.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_HOLIDAY_SURCHARGE (state, setting)
        {
            state.settingAppointmentHolidaySurcharge.id = setting.id;
            state.settingAppointmentHolidaySurcharge.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_WEEKEND_SURCHARGE (state, setting)
        {
            state.settingAppointmentWeekendSurcharge.id = setting.id;
            state.settingAppointmentWeekendSurcharge.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_FUEL_LEVY_SURCHARGE (state, setting)
        {
            state.settingAppointmentFuelLevySurcharge.id = setting.id;
            state.settingAppointmentFuelLevySurcharge.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_FIRST_APPOINTMENT_DISCOUNT (state, setting)
        {
            state.settingAppointmentFirstAppointmentDiscount.id = setting.id;
            state.settingAppointmentFirstAppointmentDiscount.value = setting.value;
        },
        SET_SETTING_DISCOUNT_PARKING_DISCOUNT_OVER_COUPON_DISCOUNT (state, setting)
        {
            state.settingDiscountParkingDiscountOverCouponDiscount.id = setting.id;
            state.settingDiscountParkingDiscountOverCouponDiscount.value = setting.value;
        },
        SET_SETTING_DISCOUNT_ONLINE_APPOINTMENT_DISCOUNT_OVER_COUPON_DISCOUNT (state, setting)
        {
            state.settingDiscountOnlineAppointmentDiscountOverCouponDiscount.id = setting.id;
            state.settingDiscountOnlineAppointmentDiscountOverCouponDiscount.value = setting.value;
        },
        SET_SETTING_COUPON_WEEKEND_DAYS (state, setting)
        {
            state.settingCouponWeekendDays.id = setting.id;
            state.settingCouponWeekendDays.value = setting.value;
        },
        SET_SETTING_APPOINTMENT_ONLINE_APPOINTMENT_DISCOUNT (state, setting)
        {
            state.settingAppointmentOnlineAppointmentDiscount.id = setting.id;
            state.settingAppointmentOnlineAppointmentDiscount.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_ONLINE_APPOINTMENT_WITHOUT_PAYMENT_STATUS (state, setting)
        {
            state.settingAppointmentOnlineAppointmentWithoutPaymentStatus.id = setting.id;
            state.settingAppointmentOnlineAppointmentWithoutPaymentStatus.value = setting.value;
        },

        SET_SETTING_ONLINE_APPOINTMENT_WITH_PARTIAL_PAYMENT_STATUS(state, setting) {
            state.settingAppointmentOnlineAppointmentWithPartialPaymentStatus.id = setting.id;
            state.settingAppointmentOnlineAppointmentWithPartialPaymentStatus.value = setting.value;
        },
        SET_SETTING_ONLINE_APPOINTMENT_WITH_UNDECIDED_STATUS(state, setting) {
            state.settingAppointmentOnlineAppointmentWithUndecidedStatus.id = setting.id;
            state.settingAppointmentOnlineAppointmentWithUndecidedStatus.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_SERVICE_EXTRA_DURATION_UNIT_AND_CHARGE_RATE (state, setting)
        {
            state.settingAppointmentServiceExtraDurationUnitAndChargeRate.id = setting.id;
            state.settingAppointmentServiceExtraDurationUnitAndChargeRate.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_CREATION_EMAIL_NOTIFY_FOR_SYSTEM (state, setting)
        {

            state.settingAppointmentCreationEmailNotifyForSystem.id = setting.id;
            let { recipients, cc_recipients, bcc_recipients } = setting.value;
            let recipientsArrayOfObj = [];
            let ccRecipientsArrayOfObj = [];
            let bccRecipientsArrayOfObj = [];
            if (recipients.length > 0) {
                recipients.map((currentValue) =>
                {
                    recipientsArrayOfObj.push({
                        key: currentValue,
                        value: currentValue
                    })
                })
            }
            if (cc_recipients.length > 0) {
                cc_recipients.map((currentValue) =>
                {
                    ccRecipientsArrayOfObj.push({
                        key: currentValue,
                        value: currentValue
                    })
                })
            }
            if (bcc_recipients.length > 0) {
                bcc_recipients.map((currentValue) =>
                {
                    bccRecipientsArrayOfObj.push({
                        key: currentValue,
                        value: currentValue
                    })
                })
            }


            state.settingAppointmentCreationEmailNotifyForSystem.value = {
                ...setting.value, recipients: recipientsArrayOfObj, cc_recipients: ccRecipientsArrayOfObj,
                bcc_recipients: bccRecipientsArrayOfObj
            };
        },


        SET_SETTING_APPOINTMENT_CREATION_EMAIL_NOTIFY_FOR_TECHNICIAN (state, setting)
        {

            state.settingAppointmentCreationEmailNotifyForTechnician.id = setting.id;
            let { cc_recipients, bcc_recipients } = setting.value;

            let ccRecipientsArrayOfObj = [];
            let bccRecipientsArrayOfObj = [];

            if (cc_recipients.length > 0) {
                cc_recipients.map((currentValue) =>
                {
                    ccRecipientsArrayOfObj.push({
                        key: currentValue,
                        value: currentValue
                    })
                })
            }
            if (bcc_recipients.length > 0) {
                bcc_recipients.map((currentValue) =>
                {
                    bccRecipientsArrayOfObj.push({
                        key: currentValue,
                        value: currentValue
                    })
                })
            }


            state.settingAppointmentCreationEmailNotifyForTechnician.value = {
                ...setting.value, cc_recipients: ccRecipientsArrayOfObj,
                bcc_recipients: bccRecipientsArrayOfObj
            };
        },
        SET_SETTING_APPOINTMENT_CREATION_EMAIL_NOTIFY_FOR_CUSTOMER (state, setting)
        {

            state.settingAppointmentCreationEmailNotifyForCustomer.id = setting.id;
            let { cc_recipients, bcc_recipients } = setting.value;

            let ccRecipientsArrayOfObj = [];
            let bccRecipientsArrayOfObj = [];

            if (cc_recipients.length > 0) {
                cc_recipients.map((currentValue) =>
                {
                    ccRecipientsArrayOfObj.push({
                        key: currentValue,
                        value: currentValue
                    })
                })
            }
            if (bcc_recipients.length > 0) {
                bcc_recipients.map((currentValue) =>
                {
                    bccRecipientsArrayOfObj.push({
                        key: currentValue,
                        value: currentValue
                    })
                })
            }


            state.settingAppointmentCreationEmailNotifyForCustomer.value = {
                ...setting.value, cc_recipients: ccRecipientsArrayOfObj,
                bcc_recipients: bccRecipientsArrayOfObj
            };
        },
        SET_SETTING_APPOINTMENT_CREATION_EMAIL_NOTIFY_CUSTOMER (state, setting)
        {

            state.settingAppointmentCreationEmailNotifyCustomer.id = setting.id;
            let { cc_recipients, bcc_recipients } = setting.value;

            let ccRecipientsArrayOfObj = [];
            let bccRecipientsArrayOfObj = [];

            if (cc_recipients.length > 0) {
                cc_recipients.map((currentValue) =>
                {
                    ccRecipientsArrayOfObj.push({
                        key: currentValue,
                        value: currentValue
                    })
                })
            }
            if (bcc_recipients.length > 0) {
                bcc_recipients.map((currentValue) =>
                {
                    bccRecipientsArrayOfObj.push({
                        key: currentValue,
                        value: currentValue
                    })
                })
            }


            state.settingAppointmentCreationEmailNotifyCustomer.value = {
                ...setting.value, cc_recipients: ccRecipientsArrayOfObj,
                bcc_recipients: bccRecipientsArrayOfObj
            };
        },

        SET_SETTING_APPOINTMENT_STATUS_UPDATE_EMAIL_NOTIFY_FOR_TECHNICIAN (state, setting)
        {

            state.settingAppointmentStatusUpdateEmailNotifyForTechnician.id = setting.id;
            let { cc_recipients, bcc_recipients } = setting.value;

            let ccRecipientsArrayOfObj = [];
            let bccRecipientsArrayOfObj = [];




            cc_recipients.map((currentValue) =>
            {
                ccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })


            bcc_recipients.map((currentValue) =>
            {
                bccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })



            state.settingAppointmentStatusUpdateEmailNotifyForTechnician.value = {
                ...setting.value, cc_recipients: ccRecipientsArrayOfObj,
                bcc_recipients: bccRecipientsArrayOfObj
            };



        },
        SET_SETTING_APPOINTMENT_STATUS_UPDATE_EMAIL_NOTIFY_FOR_CUSTOMER (state, setting)
        {

            state.settingAppointmentStatusUpdateEmailNotifyForCustomer.id = setting.id;
            let { cc_recipients, bcc_recipients } = setting.value;

            let ccRecipientsArrayOfObj = [];
            let bccRecipientsArrayOfObj = [];




            cc_recipients.map((currentValue) =>
            {
                ccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })


            bcc_recipients.map((currentValue) =>
            {
                bccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })



            state.settingAppointmentStatusUpdateEmailNotifyForCustomer.value = {
                ...setting.value, cc_recipients: ccRecipientsArrayOfObj,
                bcc_recipients: bccRecipientsArrayOfObj
            };



        },

        SET_SETTING_APPOINTMENT_CANCELLATION_POLICY_BY_CUSTOMER (state, setting)
        {
            state.settingAppointmentCancellationPolicyByCustomer.id = setting.id;
            state.settingAppointmentCancellationPolicyByCustomer.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_REFUND_POLICY (state, setting)
        {
            state.settingAppointmentRefundPolicy.id = setting.id;
            state.settingAppointmentRefundPolicy.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_CREDITED_POLICY (state, setting)
        {
            state.settingAppointmentCreditedPolicy.id = setting.id;
            state.settingAppointmentCreditedPolicy.value = setting.value;
        },
        SET_SETTING_APPOINTMENT_RESCHEDULE_POLICY (state, setting)
        {
            state.settingAppointmentReschedulePolicy.id = setting.id;
            state.settingAppointmentReschedulePolicy.value = setting.value;
        },

        SET_SETTING_ORDER_ORDER_NUMBER_PREFIX (state, setting)
        {
            state.settingOrderOrderNumberPrefix.id = setting.id;
            state.settingOrderOrderNumberPrefix.value = setting.value;
        },

        SET_SETTING_ORDER_ORDER_NUMBER_SUFFIX (state, setting)
        {
            state.settingOrderOrderNumberSuffix.id = setting.id;
            state.settingOrderOrderNumberSuffix.value = setting.value;
        },

        SET_SETTING_ORDER_DELIVERY_CHARGE (state, setting)
        {
            state.settingOrderDeliveryCharge.id = setting.id;
            state.settingOrderDeliveryCharge.value = setting.value;
        },
        SET_SETTING_ORDER_REGISTERED_ADDRESSES (state, setting)
        {

            setting.value.map((currentValue) =>
            {
                currentValue.addresses.forEach((item, index) =>
                {

                    currentValue.addresses[index] = {
                        key: item,
                        value: item
                    }
                })
            })

            state.settingOrderRegisteredAddresses.id = setting.id;
            state.settingOrderRegisteredAddresses.value = setting.value;
        },
        SET_SETTING_ORDER_ON_SITE_MAXIMUM_DISTANCE (state, setting)
        {

            state.settingOrderOnSiteMaximumDistance.id = setting.id;
            state.settingOrderOnSiteMaximumDistance.value = setting.value;
        },
        SET_SETTING_ORDER_CREATION_EMAIL_NOTIFY_FOR_SYSTEM (state, setting)
        {

            state.settingOrderCreationEmailNotifyForSystem.id = setting.id;
            let { recipients, cc_recipients, bcc_recipients } = setting.value;
            let recipientsArrayOfObj = [];
            let ccRecipientsArrayOfObj = [];
            let bccRecipientsArrayOfObj = [];

            recipients.map((currentValue) =>
            {
                recipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })


            cc_recipients.map((currentValue) =>
            {
                ccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })


            bcc_recipients.map((currentValue) =>
            {
                bccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })



            state.settingOrderCreationEmailNotifyForSystem.value = {
                ...setting.value, recipients: recipientsArrayOfObj, cc_recipients: ccRecipientsArrayOfObj,
                bcc_recipients: bccRecipientsArrayOfObj
            };



        },
        SET_SETTING_ORDER_STATUS_UPDATE_EMAIL_NOTIFY_FOR_SYSTEM (state, setting)
        {

            state.settingOrderStatusUpdateEmailNotifyForSystem.id = setting.id;
            let { recipients, cc_recipients, bcc_recipients } = setting.value;
            let recipientsArrayOfObj = [];
            let ccRecipientsArrayOfObj = [];
            let bccRecipientsArrayOfObj = [];

            recipients.map((currentValue) =>
            {
                recipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })


            cc_recipients.map((currentValue) =>
            {
                ccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })


            bcc_recipients.map((currentValue) =>
            {
                bccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })



            state.settingOrderStatusUpdateEmailNotifyForSystem.value = {
                ...setting.value, recipients: recipientsArrayOfObj, cc_recipients: ccRecipientsArrayOfObj,
                bcc_recipients: bccRecipientsArrayOfObj
            };



        },
        SET_SETTING_ORDER_STATUS_UPDATE_EMAIL_NOTIFY_FOR_CUSTOMER (state, setting)
        {

            state.settingOrderStatusUpdateEmailNotifyForCustomer.id = setting.id;
            let { cc_recipients, bcc_recipients } = setting.value;
          
            let ccRecipientsArrayOfObj = [];
            let bccRecipientsArrayOfObj = [];

          


            cc_recipients.map((currentValue) =>
            {
                ccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })


            bcc_recipients.map((currentValue) =>
            {
                bccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })



            state.settingOrderStatusUpdateEmailNotifyForCustomer.value = {
                ...setting.value, cc_recipients: ccRecipientsArrayOfObj,
                bcc_recipients: bccRecipientsArrayOfObj
            };



        },

        SET_SETTING_ORDER_CREATION_EMAIL_NOTIFY_FOR_CUSTOMER (state, setting)
        {

            state.settingOrderCreationEmailNotifyForCustomer.id = setting.id;
            let { cc_recipients, bcc_recipients } = setting.value;

            let ccRecipientsArrayOfObj = [];
            let bccRecipientsArrayOfObj = [];

            cc_recipients.map((currentValue) =>
            {
                ccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })


            bcc_recipients.map((currentValue) =>
            {
                bccRecipientsArrayOfObj.push({
                    key: currentValue,
                    value: currentValue
                })
            })



            state.settingOrderCreationEmailNotifyForCustomer.value = {
                ...setting.value, cc_recipients: ccRecipientsArrayOfObj,
                bcc_recipients: bccRecipientsArrayOfObj
            };



        },

        SET_SETTING_PRODUCT_PRODUCT_CODE_PREFIX (state, setting)
        {
            state.settingProductProductCodePrefix.id = setting.id;
            state.settingProductProductCodePrefix.value = setting.value;
        },


        SET_SETTING_PRODUCT_PRODUCT_CODE_SUFFIX (state, setting)
        {
            state.settingProductProductCodeSuffix.id = setting.id;
            state.settingProductProductCodeSuffix.value = setting.value;
        },

        SET_SETTING_PRODUCT_ITEM_CONDITION (state, setting)
        {
            state.settingProductItemCondition.id = setting.id;
            state.settingProductItemCondition.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_SERVICE_DURATION (state, setting)
        {
            state.settingAppointmentServiceDuration.id = setting.id;
            state.settingAppointmentServiceDuration.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_ON_SITE_MAXIMUM_DISTANCE (state, setting)
        {
            state.settingAppointmentOnSiteMaximumDistance.id = setting.id;
            state.settingAppointmentOnSiteMaximumDistance.value = setting.value;
        },
        SET_SETTING_APPOINTMENT_PARKING_DISCOUNT (state, setting)
        {
            state.settingAppointmentParkingDiscount.id = setting.id;
            state.settingAppointmentParkingDiscount.value = setting.value;
        },

        SET_SETTING_APPOINTMENT_REGISTERED_ADDRESSES (state, setting)
        {
            state.settingAppointmentRegisteredAddresses.id = setting.id;
            state.settingAppointmentRegisteredAddresses.value = setting.value;
        },

        SET_SETTING_LEAVE_APPLICATION_TYPE (state, setting)
        {
            state.settingLeaveApplicationType.id = setting.id;
            state.settingLeaveApplicationType.value = setting.value;
        },

        SET_SETTING_LEAVE_APPLICATION_STATUS (state, setting)
        {
            state.settingLeaveApplicationStatus.id = setting.id;
            state.settingLeaveApplicationStatus.value = setting.value;
        },
        SET_SETTING_DEFAULT_GST_RATE (state, setting)
        {
            state.settingDefaultGstRate.id = setting.id;
            state.settingDefaultGstRate.value = setting.value;
        },
        SET_SETTING_ORDER_STATUS (state, setting)
        {
            state.settingOrderStatus.id = setting.id;
            state.settingOrderStatus.value = setting.value;
        },
        SET_SETTING_ORDER_NOTE_TYPE(state, setting) {
            state.settingOrderNoteType.value = setting.value;
        },
        SET_SETTING_REFUND_NOTE_TYPE(state, setting) {
            state.settingRefundNoteType.value = setting.value;
        },
        SET_SETTING_DEFAULT_COMPANY_NAME (state, setting)
        {
            state.settingDefaultCompanyName.value = setting.value;
            state.settingDefaultCompanyName.id = setting.id;
        },
        SET_SETTING_DEFAULT_COMPANY_ABN (state, setting)
        {
            state.settingDefaultCompanyABN.value = setting.value;
            state.settingDefaultCompanyABN.id = setting.id;
        },
        SET_SETTING_DEFAULT_COMPANY_ADDRESS (state, setting)
        {
            state.settingDefaultCompanyAddress.value = setting.value;
            state.settingDefaultCompanyAddress.id = setting.id;
        },
        SET_SETTING_DEFAULT_STATE_COMPANY_ADDRESS (state, setting)
        {
            state.settingDefaultStateCompanyAddress.value = setting.value;
            state.settingDefaultStateCompanyAddress.id = setting.id;
        },

        SET_SETTING_VACANCY_APPLY_EMAIL_ADDRESS (state, setting)
        {
            state.settingVacancyApplyEmailAddress.value = setting.value;
            state.settingVacancyApplyEmailAddress.id = setting.id;
        },
        SET_SETTING_PRODUCT_CODE_PREFIX (state, setting)
        {
            state.settingProductCodePrefix.id = setting.id;
            state.settingProductCodePrefix.value = setting.value;
        },
        SET_SETTING_PRODUCT_CODE_SUFFIX (state, setting)
        {
            state.settingProductCodeSuffix.id = setting.id;
            state.settingProductCodeSuffix.value = setting.value;
        },
        SET_SETTING_CONTACT_REFERENCE_PREFIX(state, setting)
        {
            state.settingContactReferencePrefix.id = setting.id;
            state.settingContactReferencePrefix.value = setting.value;
        },
        SET_SETTING_CONTACT_REFERENCE_SUFFIX(state, setting)
        {
            state.settingContactReferenceSuffix.id = setting.id;
            state.settingContactReferenceSuffix.value = setting.value;
        },
        SET_SETTING_CONTACT_REQUEST_FOR_LIST(state, setting)
        {
            state.settingContactRequestForList.id = setting.id;
            state.settingContactRequestForList.value = setting.value;
        },
        SET_SETTING_CALL_REQUEST_REFERENCE_PREFIX(state, setting)
        {
            state.settingCallRequestReferencePrefix.id = setting.id;
            state.settingCallRequestReferencePrefix.value = setting.value;
        },
        SET_SETTING_CALL_REQUEST_REFERENCE_SUFFIX(state, setting)
        {
            state.settingCallRequestReferenceSuffix.id = setting.id;
            state.settingCallRequestReferenceSuffix.value = setting.value;
        },
        SET_SETTING_TECHNICIAN_COMMISSION_PAYMENT_REFERENCE_PREFIX(state, setting)
        {
            state.settingTechnicianCommissionPaymentReferencePrefix.id = setting.id;
            state.settingTechnicianCommissionPaymentReferencePrefix.value = setting.value;
        },
        SET_SETTING_TECHNICIAN_COMMISSION_PAYMENT_REFERENCE_SUFFIX(state, setting)
        {
            state.settingTechnicianCommissionPaymentReferenceSuffix.id = setting.id;
            state.settingTechnicianCommissionPaymentReferenceSuffix.value = setting.value;
        },
        SET_SETTING_PAYMENT_CODE_PREFIX (state, setting)
        {
            state.settingPaymentCodePrefix.id = setting.id;
            state.settingPaymentCodePrefix.value = setting.value;
        },

        SET_SETTING_PAYMENT_CODE_SUFFIX (state, setting)
        {
            state.settingPaymentCodeSuffix.id = setting.id;
            state.settingPaymentCodeSuffix.value = setting.value;
        },

        SET_SETTING_PAYMENT_CARD_SURCHARGE (state, setting)
        {
            state.settingPaymentCardSurcharge.id = setting.id;
            state.settingPaymentCardSurcharge.value = setting.value;
        },

        SET_SETTING_GOOGLE_MAP_DISTANCE_MATRIX_API_KEY (state, setting)
        {
            state.settingGoogleMapDistanceMatrixApiKey.id = setting.id;
            state.settingGoogleMapDistanceMatrixApiKey.value = setting.value;
        },

        SET_SETTING_GOOGLE_MAP_DISTANCE_MATRIX_API_ENDPOINT (state, setting)
        {
            state.settingGoogleMapDistanceMatrixApiEndpoint.id = setting.id;
            state.settingGoogleMapDistanceMatrixApiEndpoint.value = setting.value;
        },

        SET_SETTING_VENDOR_CODE_PREFIX (state, setting)
        {
            state.settingVendorCodePrefix.id = setting.id;
            state.settingVendorCodePrefix.value = setting.value;
        },
        SET_SETTING_VENDOR_CODE_SUFFIX (state, setting)
        {
            state.settingVendorCodeSuffix.id = setting.id;
            state.settingVendorCodeSuffix.value = setting.value;
        },
        SET_SETTING_FRANCHISEE_CODE_PREFIX (state, setting)
        {
            state.settingFranchiseeCodePrefix.id = setting.id;
            state.settingFranchiseeCodePrefix.value = setting.value;
        },
        SET_SETTING_FRANCHISEE_CODE_SUFFIX (state, setting)
        {
            state.settingFranchiseeCodeSuffix.id = setting.id;
            state.settingFranchiseeCodeSuffix.value = setting.value;
        },
        SET_SETTING_FRANCHISEE_PAYMENT_REFERENCE_PREFIX (state, setting)
        {
            state.settingFranchiseePaymentReferencePrefix.id = setting.id;
            state.settingFranchiseePaymentReferencePrefix.value = setting.value;
        },
        SET_SETTING_FRANCHISEE_PAYMENT_REFERENCE_SUFFIX (state, setting)
        {
            state.settingFranchiseePaymentReferenceSuffix.id = setting.id;
            state.settingFranchiseePaymentReferenceSuffix.value = setting.value;
        },
        SET_SETTING_DEFAULT_TECHNICIAN_MOB_APP (state, setting)
        {
            state.settingDefaultTechnicianMobApp.id = setting.id;
            state.settingDefaultTechnicianMobApp.value = setting.value;
        },
        SET_SETTING_DEFAULT_CUSTOMER_MOB_APP (state, setting)
        {
            state.settingDefaultCustomerMobApp.id = setting.id;
            state.settingDefaultCustomerMobApp.value = setting.value;
        },
        SET_SETTING_DEFAULT_INTERNAL_EMAIL_HEADER (state, setting)
        {
            state.settingDefaultInternalEmailHeader.id = setting.id;
            state.settingDefaultInternalEmailHeader.value = setting.value;
            state.settingDefaultInternalEmailHeader.description = setting.description;
        },
        SET_SETTING_DEFAULT_INTERNAL_EMAIL_FOOTER (state, setting)
        {
            state.settingDefaultInternalEmailFooter.id = setting.id;
            state.settingDefaultInternalEmailFooter.value = setting.value;
            state.settingDefaultInternalEmailFooter.description = setting.description;
        },
        SET_SETTING_ORDER_JOB_STATUS (state, setting)
        {
            state.settingOrderJobStatus.id = setting.id;
            state.settingOrderJobStatus.value = setting.value;
        },
        SET_SETTING_PAYMENT_SIMPLIFY_GATEWAY_3DS_STATUS (state, setting)
        {

            state.settingPaymentSimplifyGateway3DSStatus.id = setting.id;
            state.settingPaymentSimplifyGateway3DSStatus.value = setting.value;
        },

        SET_SETTING_DEFAULT_SMS_TEMPLATE_LIST(state, setting)
        {
            state.settingDefaultSmsTemplateList.id = setting.id;
            state.settingDefaultSmsTemplateList.value = setting.value;
        },

        SET_SETTING_DEFAULT_APPOINTMENT_PAYMENT_COLLECTION_STAGE_TEMPLATE(state, setting)
        {
            state.settingDefaultAppointmentPaymentCollectionStageTemplate.id = setting.id;
            state.settingDefaultAppointmentPaymentCollectionStageTemplate.value = setting.value;
        },

    },

    actions: {
        getSettings: async ({dispatch}, params) => {
            const path = `v1/settings`;

            return axios.get(path, { params: params }).then((response) =>
            {
                if (params.type?.[0] === 'notification') {
                    return {
                        message: '',
                        type: 'success',
                        status: response.status,
                        data: response.data
                    };
                }
                dispatch('commitSettings', response.data);
                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };

            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        putSetting: async ({ dispatch }, dataObj) =>
        {
            const path = `v1/settings/${dataObj.id}`;
            const data = dataObj.data;

            return axios.put(path, data).then((response) =>
            {
                let arr = [];
                arr.push(response.data.data);
                dispatch('commitSettings', {
                    data: arr
                });

                return {
                    message: '',
                    type: 'success',
                    status: response.status,
                };
            }).catch((error) =>
            {
                return responseErrorHandler(error);
            });
        },

        commitSettings: async ({commit}, responseData) => {

            responseData.data.forEach((settingObject) =>
            {
                if (('default_website_logo_regular' in settingObject) && (settingObject.default_website_logo_regular.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_WEBSITE_LOGO_REGULAR', settingObject.default_website_logo_regular);
                }
                if (('default_website_logo_lite' in settingObject) && (settingObject.default_website_logo_lite.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_WEBSITE_LOGO_LITE', settingObject.default_website_logo_lite);
                  }
                if (('default_website_favicon' in settingObject) && (settingObject.default_website_favicon.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_WEBSITE_FAVICON', settingObject.default_website_favicon);
                }
                if (('default_customer_app_image' in settingObject) && (settingObject.default_customer_app_image.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_CUSTOMER_APP_IMAGE', settingObject.default_customer_app_image);
                }
                if (('default_website_header_banner_slider' in settingObject) && (settingObject.default_website_header_banner_slider.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_WEBSITE_HEADER_BANNER_SLIDER', settingObject.default_website_header_banner_slider);
                }
                if (('default_pagination' in settingObject) && (settingObject.default_pagination.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_PAGINATION', settingObject.default_pagination);
                }
                if (('default_website_url' in settingObject) && (settingObject.default_website_url.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_WEBSITE_URL', settingObject.default_website_url);
                }
                if (('default_technician_mob_app' in settingObject) && (settingObject.default_technician_mob_app.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_TECHNICIAN_MOB_APP', settingObject.default_technician_mob_app);
                }
                if (('default_customer_mob_app' in settingObject) && (settingObject.default_customer_mob_app.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_CUSTOMER_MOB_APP', settingObject.default_customer_mob_app);
                }
                if (('default_internal_email_header' in settingObject) && (settingObject.default_internal_email_header.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_INTERNAL_EMAIL_HEADER', settingObject.default_internal_email_header);
                }
                if (('default_internal_email_footer' in settingObject) && (settingObject.default_internal_email_footer.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_INTERNAL_EMAIL_FOOTER', settingObject.default_internal_email_footer);
                }
                if (('default_website_customer_portal_login_url' in settingObject) && (settingObject.default_website_customer_portal_login_url.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_WEBSITE_CUSTOMER_PORTAL_LOGIN_URL', settingObject.default_website_customer_portal_login_url);
                }
                if (('default_website_privacy_policy_url' in settingObject) && (settingObject.default_website_privacy_policy_url.type === 'default')) {
                commit('SET_SETTING_DEFAULT_WEBSITE_PRIVACY_POLICY_URL', settingObject.default_website_privacy_policy_url);
                }
                if (('default_website_terms_and_conditions_url' in settingObject) && (settingObject.default_website_terms_and_conditions_url.type === 'default')) {
                commit('SET_SETTING_DEFAULT_WEBSITE_TERMS_AND_CONDITIONS_URL', settingObject.default_website_terms_and_conditions_url);
                }
                if (('default_admin_panel_url' in settingObject) && (settingObject.default_admin_panel_url.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_ADMIN_PANEL_URL', settingObject.default_admin_panel_url);
                }
                if (('default_website_about_us_url' in settingObject) && (settingObject.default_website_about_us_url.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_WEBSITE_ABOUT_US_URL', settingObject.default_website_about_us_url);
                }
                if (('default_website_medical_service_page_url' in settingObject) && (settingObject.default_website_medical_service_page_url.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_WEBSITE_MEDICAL_SERVICE_PAGE_URL', settingObject.default_website_medical_service_page_url);
                }
                if (('default_website_ndis_page_url' in settingObject) && (settingObject.default_website_ndis_page_url.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_WEBSITE_NDIS_PAGE_URL', settingObject.default_website_ndis_page_url);
                }
                if (('default_website_faq_url' in settingObject) && (settingObject.default_website_faq_url.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_WEBSITE_FAQ_URL', settingObject.default_website_faq_url);
                }
                if (('default_customer_care_email_address' in settingObject) && (settingObject.default_customer_care_email_address.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_CUSTOMER_CARE_EMAIL_ADDRESS', settingObject.default_customer_care_email_address);
                }
                if (('default_company_social_link_facebook' in settingObject) && (settingObject.default_company_social_link_facebook.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_COMPANY_SOCIAL_LINK_FACEBOOK', settingObject.default_company_social_link_facebook);
                }
                if (('default_company_social_link_linkedin' in settingObject) && (settingObject.default_company_social_link_linkedin.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_COMPANY_SOCIAL_LINK_LINKEDIN', settingObject.default_company_social_link_linkedin);
                }
                if (('default_company_social_link_youtube' in settingObject) && (settingObject.default_company_social_link_youtube.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_COMPANY_SOCIAL_LINK_YOUTUBE', settingObject.default_company_social_link_youtube);
                }
                if (('default_company_social_link_instagram' in settingObject) && (settingObject.default_company_social_link_instagram.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_COMPANY_SOCIAL_LINK_INSTAGRAM', settingObject.default_company_social_link_instagram);
                }
                if (('default_contact_number' in settingObject) && (settingObject.default_contact_number.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_CONTACT_NUMBER', settingObject.default_contact_number);
                }
                if (('default_state_contact_number' in settingObject) && (settingObject.default_state_contact_number.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_STATE_CONTACT_NUMBER', settingObject.default_state_contact_number);
                }
                if (('default_state_timezone' in settingObject) && (settingObject.default_state_timezone.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_STATE_TIMEZONE', settingObject.default_state_timezone);
                }

                if (('default_parking_type' in settingObject) && (settingObject.default_parking_type.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_PARKING_TYPE', settingObject.default_parking_type);
                }

                if (('default_state' in settingObject) && (settingObject.default_state.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_STATE', settingObject.default_state);
                }

                if (('default_customer_type' in settingObject) && (settingObject.default_customer_type.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_CUSTOMER_TYPE', settingObject.default_customer_type);
                }

                if (('default_customer_role' in settingObject) && (settingObject.default_customer_role.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_CUSTOMER_ROLE', settingObject.default_customer_role);
                }

                if (('service_code_prefix' in settingObject) && (settingObject.service_code_prefix.type === 'service')) {
                    commit('SET_SETTING_SERVICE_CODE_PREFIX', settingObject.service_code_prefix);
                }
                if (('service_code_suffix' in settingObject) && (settingObject.service_code_suffix.type === 'service')) {
                    commit('SET_SETTING_SERVICE_CODE_SUFFIX', settingObject.service_code_suffix);
                }

                if (('service_state_price_rate' in settingObject) && (settingObject.service_state_price_rate.type === 'service')) {
                    commit('SET_SETTING_SERVICE_STATE_PRICE_RATE', settingObject.service_state_price_rate);
                }
                if (('service_offer_price' in settingObject) && (settingObject.service_offer_price.type === 'service')) {
                    commit('SET_SETTING_SERVICE_OFFER_PRICE', settingObject.service_offer_price);
                }
                if (('default_service_global_flat_price' in settingObject) && (settingObject.default_service_global_flat_price.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_SERVICE_GLOBAL_FLAT_PRICE', settingObject.default_service_global_flat_price);
                }

                if (('technician_working_schedule' in settingObject) && (settingObject.technician_working_schedule.type === 'technician')) {
                    commit('SET_SETTING_TECHNICIAN_WORKING_SCHEDULE', settingObject.technician_working_schedule);
                }

                if (('customer_referral_code_prefix' in settingObject) && (settingObject.customer_referral_code_prefix.type === 'customer')) {
                    commit('SET_SETTING_CUSTOMER_REFERRAL_CODE_PREFIX', settingObject.customer_referral_code_prefix);
                }

                if (('customer_referral_code_suffix' in settingObject) && (settingObject.customer_referral_code_suffix.type === 'customer')) {
                    commit('SET_SETTING_CUSTOMER_REFERRAL_CODE_SUFFIX', settingObject.customer_referral_code_suffix);
                }

                if (('appointment_reference_prefix' in settingObject) && (settingObject.appointment_reference_prefix.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_REFERENCE_PREFIX', settingObject.appointment_reference_prefix);
                }

                if (('appointment_reference_suffix' in settingObject) && (settingObject.appointment_reference_suffix.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_REFERENCE_SUFFIX', settingObject.appointment_reference_suffix);
                }

                if (('appointment_status' in settingObject) && (settingObject.appointment_status.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_STATUS', settingObject.appointment_status);
                }

                if (('appointment_service_platform' in settingObject) && (settingObject.appointment_service_platform.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_SERVICE_PLATFORM', settingObject.appointment_service_platform);
                }

                if (('appointment_note_type' in settingObject) && (settingObject.appointment_note_type.type === 'appointment')) {

                    commit('SET_SETTING_APPOINTMENT_NOTE_TYPE', settingObject.appointment_note_type);
                }

                if (('appointment_online_booking_status' in settingObject) && (settingObject.appointment_online_booking_status.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_ONLINE_BOOKING_STATUS', settingObject.appointment_online_booking_status);
                }

                if (('appointment_same_day_booking_status' in settingObject) && (settingObject.appointment_same_day_booking_status.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_SAME_DAY_BOOKING_STATUS', settingObject.appointment_same_day_booking_status);
                }

                if (('appointment_creation_email_notify_for_system' in settingObject) && (settingObject.appointment_creation_email_notify_for_system.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_CREATION_EMAIL_NOTIFY_FOR_SYSTEM', settingObject.appointment_creation_email_notify_for_system);
                }
                if (('appointment_creation_email_notify_for_technician' in settingObject) && (settingObject.appointment_creation_email_notify_for_technician.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_CREATION_EMAIL_NOTIFY_FOR_TECHNICIAN', settingObject.appointment_creation_email_notify_for_technician);
                }
                if (('appointment_creation_email_notify_for_customer' in settingObject) && (settingObject.appointment_creation_email_notify_for_customer.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_CREATION_EMAIL_NOTIFY_FOR_CUSTOMER', settingObject.appointment_creation_email_notify_for_customer);
                }
                if (('appointment_online_appointment_discount' in settingObject) && (settingObject.appointment_online_appointment_discount.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_ONLINE_APPOINTMENT_DISCOUNT', settingObject.appointment_online_appointment_discount);
                }

                if (('appointment_online_appointment_without_payment_status' in settingObject) && (settingObject.appointment_online_appointment_without_payment_status.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_ONLINE_APPOINTMENT_WITHOUT_PAYMENT_STATUS', settingObject.appointment_online_appointment_without_payment_status);
                }

                if (('appointment_online_appointment_with_partial_payment_status' in settingObject) && (settingObject.appointment_online_appointment_with_partial_payment_status.type === 'appointment')) {
                    commit('SET_SETTING_ONLINE_APPOINTMENT_WITH_PARTIAL_PAYMENT_STATUS', settingObject.appointment_online_appointment_with_partial_payment_status);
                }
                if (('appointment_online_appointment_with_undecided_status' in settingObject) && (settingObject.appointment_online_appointment_with_undecided_status.type === 'appointment')) {
                    commit('SET_SETTING_ONLINE_APPOINTMENT_WITH_UNDECIDED_STATUS', settingObject.appointment_online_appointment_with_undecided_status);
                }

                if (('appointment_service_extra_duration_unit_and_charge_rate' in settingObject) && (settingObject.appointment_service_extra_duration_unit_and_charge_rate.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_SERVICE_EXTRA_DURATION_UNIT_AND_CHARGE_RATE', settingObject.appointment_service_extra_duration_unit_and_charge_rate);
                }
                if (('appointment_status_update_email_notify_for_technician' in settingObject) && (settingObject.appointment_status_update_email_notify_for_technician.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_STATUS_UPDATE_EMAIL_NOTIFY_FOR_TECHNICIAN', settingObject.appointment_status_update_email_notify_for_technician);
                }
                if (('appointment_status_update_email_notify_for_customer' in settingObject) && (settingObject.appointment_status_update_email_notify_for_customer.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_STATUS_UPDATE_EMAIL_NOTIFY_FOR_CUSTOMER', settingObject.appointment_status_update_email_notify_for_customer);
                }

                if (('appointment_same_day_surcharge' in settingObject) && (settingObject.appointment_same_day_surcharge.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_SAME_DAY_SURCHARGE', settingObject.appointment_same_day_surcharge);
                }
                if (('appointment_timebase_surcharge' in settingObject) && (settingObject.appointment_timebase_surcharge.type === 'appointment')) {

                    commit('SET_SETTING_APPOINTMENT_TIMEBASE_SURCHARGE', settingObject.appointment_timebase_surcharge);
                }
                if (('appointment_distance_surcharge' in settingObject) && (settingObject.appointment_distance_surcharge.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_DISTANCE_SURCHARGE', settingObject.appointment_distance_surcharge);
                }
                if (('appointment_holiday_surcharge' in settingObject) && (settingObject.appointment_holiday_surcharge.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_HOLIDAY_SURCHARGE', settingObject.appointment_holiday_surcharge);
                }
                if (('appointment_weekend_surcharge' in settingObject) && (settingObject.appointment_weekend_surcharge.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_WEEKEND_SURCHARGE', settingObject.appointment_weekend_surcharge);
                }
                if (('appointment_fuel_levy_surcharge' in settingObject) && (settingObject.appointment_fuel_levy_surcharge.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_FUEL_LEVY_SURCHARGE', settingObject.appointment_fuel_levy_surcharge);
                }
                if (('appointment_first_appointment_discount' in settingObject) && (settingObject.appointment_first_appointment_discount.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_FIRST_APPOINTMENT_DISCOUNT', settingObject.appointment_first_appointment_discount);
                }
                if (('appointment_cancellation_by_customer_policy' in settingObject) && (settingObject.appointment_cancellation_by_customer_policy.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_CANCELLATION_POLICY_BY_CUSTOMER', settingObject.appointment_cancellation_by_customer_policy);
                }
                if (('appointment_refund_policy' in settingObject) && (settingObject.appointment_refund_policy.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_REFUND_POLICY', settingObject.appointment_refund_policy);
                }
                if (('appointment_credited_policy' in settingObject) && (settingObject.appointment_credited_policy.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_CREDITED_POLICY', settingObject.appointment_credited_policy);
                }
                if (('appointment_reschedule_by_customer_policy' in settingObject) && (settingObject.appointment_reschedule_by_customer_policy.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_RESCHEDULE_POLICY', settingObject.appointment_reschedule_by_customer_policy);
                }
                if (('order_order_number_prefix' in settingObject) && (settingObject.order_order_number_prefix.type === 'order')) {
                    commit('SET_SETTING_ORDER_ORDER_NUMBER_PREFIX', settingObject.order_order_number_prefix);
                }
                if (('order_order_number_suffix' in settingObject) && (settingObject.order_order_number_suffix.type === 'order')) {
                    commit('SET_SETTING_ORDER_ORDER_NUMBER_SUFFIX', settingObject.order_order_number_suffix);
                }
                if (('order_delivery_charge' in settingObject) && (settingObject.order_delivery_charge.type === 'order')) {
                    commit('SET_SETTING_ORDER_DELIVERY_CHARGE', settingObject.order_delivery_charge);
                }
                if (('order_registered_addresses' in settingObject) && (settingObject.order_registered_addresses.type === 'order')) {
                    commit('SET_SETTING_ORDER_REGISTERED_ADDRESSES', settingObject.order_registered_addresses);
                }
                if (('order_on_site_maximum_distance' in settingObject) && (settingObject.order_on_site_maximum_distance.type === 'order')) {
                    commit('SET_SETTING_ORDER_ON_SITE_MAXIMUM_DISTANCE', settingObject.order_on_site_maximum_distance);
                }
                if (('order_creation_email_notify_for_system' in settingObject) && (settingObject.order_creation_email_notify_for_system.type === 'order')) {
                    commit('SET_SETTING_ORDER_CREATION_EMAIL_NOTIFY_FOR_SYSTEM', settingObject.order_creation_email_notify_for_system);
                }
                if (('order_creation_email_notify_for_customer' in settingObject) && (settingObject.order_creation_email_notify_for_customer.type === 'order')) {
                    commit('SET_SETTING_ORDER_CREATION_EMAIL_NOTIFY_FOR_CUSTOMER', settingObject.order_creation_email_notify_for_customer);
                }


                if (('order_status_update_email_notify_for_system' in settingObject) && (settingObject.order_status_update_email_notify_for_system.type === 'order')) {
                    commit('SET_SETTING_ORDER_STATUS_UPDATE_EMAIL_NOTIFY_FOR_SYSTEM', settingObject.order_status_update_email_notify_for_system);
                }
                if (('order_status_update_email_notify_for_customer' in settingObject) && (settingObject.order_status_update_email_notify_for_customer.type === 'order')) {
                    commit('SET_SETTING_ORDER_STATUS_UPDATE_EMAIL_NOTIFY_FOR_CUSTOMER', settingObject.order_status_update_email_notify_for_customer);
                }
                
                if (('product_code_prefix' in settingObject) && (settingObject.product_code_prefix.type === 'product')) {
                    commit('SET_SETTING_PRODUCT_PRODUCT_CODE_PREFIX', settingObject.product_code_prefix);
                }
                if (('product_code_suffix' in settingObject) && (settingObject.product_code_suffix.type === 'product')) {
                    commit('SET_SETTING_PRODUCT_PRODUCT_CODE_SUFFIX', settingObject.product_code_suffix);
                }
                if (('product_item_condition' in settingObject) && (settingObject.product_item_condition.type === 'product_item')) {
                    commit('SET_SETTING_PRODUCT_ITEM_CONDITION', settingObject.product_item_condition);
                }
                if (('appointment_service_duration' in settingObject) && (settingObject.appointment_service_duration.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_SERVICE_DURATION', settingObject.appointment_service_duration);
                }
                if (('appointment_on_site_maximum_distance' in settingObject) && (settingObject.appointment_on_site_maximum_distance.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_ON_SITE_MAXIMUM_DISTANCE', settingObject.appointment_on_site_maximum_distance);
                }
                if (('appointment_parking_discount' in settingObject) && (settingObject.appointment_parking_discount.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_PARKING_DISCOUNT', settingObject.appointment_parking_discount);
                }
                if (('appointment_registered_addresses' in settingObject) && (settingObject.appointment_registered_addresses.type === 'appointment')) {
                    commit('SET_SETTING_APPOINTMENT_REGISTERED_ADDRESSES', settingObject.appointment_registered_addresses);
                }

                if (('leave_application_type' in settingObject) && (settingObject.appointment.type === 'leave_application')) {
                    commit('SET_SETTING_LEAVE_APPLICATION_TYPE', settingObject.leave_application_type);
                }
                if (('leave_application_status' in settingObject) && (settingObject.appointment.type === 'leave_application')) {
                    commit('SET_SETTING_LEAVE_APPLICATION_STATUS', settingObject.leave_application_status);
                }
                if (('default_gst_rate' in settingObject) && (settingObject.default_gst_rate.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_GST_RATE', settingObject.default_gst_rate);
                }
                if (('order_status' in settingObject) && (settingObject.order_status.type === 'order')) {
                    commit('SET_SETTING_ORDER_STATUS', settingObject.order_status);
                }
                if (('order_note_type' in settingObject) && (settingObject.order_note_type.type === 'order')) {
                    commit('SET_SETTING_ORDER_NOTE_TYPE', settingObject.order_note_type);
                }
                if (('refund_note_type' in settingObject) && (settingObject.refund_note_type.type === 'refund')) {
                    commit('SET_SETTING_REFUND_NOTE_TYPE', settingObject.refund_note_type);
                }
                if (('default_company_name' in settingObject) && (settingObject.default_company_name.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_COMPANY_NAME', settingObject.default_company_name);
                }
                if (('default_company_abn' in settingObject) && (settingObject.default_company_abn.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_COMPANY_ABN', settingObject.default_company_abn);
                }
                if (('default_company_address' in settingObject) && (settingObject.default_company_address.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_COMPANY_ADDRESS', settingObject.default_company_address);
                }
                if (('default_state_company_address' in settingObject) && (settingObject.default_state_company_address.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_STATE_COMPANY_ADDRESS', settingObject.default_state_company_address);
                }
                if (('vacancy_apply_email_address' in settingObject) && (settingObject.vacancy_apply_email_address.type === 'vacancy')) {
                    commit('SET_SETTING_VACANCY_APPLY_EMAIL_ADDRESS', settingObject.vacancy_apply_email_address);
                }

                //product code settings
                if (('product_code_prefix' in settingObject) && (settingObject.product_code_prefix.type === 'product')) {
                    commit('SET_SETTING_PRODUCT_CODE_PREFIX', settingObject.product_code_prefix);
                }
                if (('product_code_suffix' in settingObject) && (settingObject.product_code_suffix.type === 'product')) {
                    commit('SET_SETTING_PRODUCT_CODE_SUFFIX', settingObject.product_code_suffix);
                }

                //contact settings
                if(('contact_reference_prefix' in settingObject) && (settingObject.contact_reference_prefix.type === 'contact')) {
                  commit('SET_SETTING_CONTACT_REFERENCE_PREFIX', settingObject.contact_reference_prefix);
                }
                if(('contact_reference_suffix' in settingObject) && (settingObject.contact_reference_suffix.type === 'contact')) {
                  commit('SET_SETTING_CONTACT_REFERENCE_SUFFIX', settingObject.contact_reference_suffix);
                }
                if(('contact_request_for_list' in settingObject) && (settingObject.contact_request_for_list.type === 'contact')) {
                    commit('SET_SETTING_CONTACT_REQUEST_FOR_LIST', settingObject.contact_request_for_list);
                }
                //call request settings
                if(('call_request_reference_prefix' in settingObject) && (settingObject.call_request_reference_prefix.type === 'call_request')) {
                    commit('SET_SETTING_CALL_REQUEST_REFERENCE_PREFIX', settingObject.call_request_reference_prefix);
                }
                if(('call_request_reference_suffix' in settingObject) && (settingObject.call_request_reference_suffix.type === 'call_request')) {
                    commit('SET_SETTING_CALL_REQUEST_REFERENCE_SUFFIX', settingObject.call_request_reference_suffix);
                }

                //technician commission payment settings
                if(('technician_commission_payment_reference_prefix' in settingObject) && (settingObject.technician_commission_payment_reference_prefix.type === 'technician_commission_payment')) {
                    commit('SET_SETTING_TECHNICIAN_COMMISSION_PAYMENT_REFERENCE_PREFIX', settingObject.technician_commission_payment_reference_prefix);
                }
                if(('technician_commission_payment_reference_suffix' in settingObject) && (settingObject.technician_commission_payment_reference_suffix.type === 'technician_commission_payment')) {
                    commit('SET_SETTING_TECHNICIAN_COMMISSION_PAYMENT_REFERENCE_SUFFIX', settingObject.technician_commission_payment_reference_suffix);
                }

                // payment settings
                if (('payment_code_prefix' in settingObject) && (settingObject.payment_code_prefix.type === 'payment')) {
                    commit('SET_SETTING_PAYMENT_CODE_PREFIX', settingObject.payment_code_prefix);
                }

                if (('payment_code_suffix' in settingObject) && (settingObject.payment_code_suffix.type === 'payment')) {
                    commit('SET_SETTING_PAYMENT_CODE_SUFFIX', settingObject.payment_code_suffix);
                }
                if (('payment_card_surcharge' in settingObject) && (settingObject.payment_card_surcharge.type === 'payment')) {
                    commit('SET_SETTING_PAYMENT_CARD_SURCHARGE', settingObject.payment_card_surcharge);
                }

                //Google map setting
                if (('google_map_distance_matrix_api_key' in settingObject) && (settingObject.google_map_distance_matrix_api_key.type === 'google_map')) {
                    commit('SET_SETTING_GOOGLE_MAP_DISTANCE_MATRIX_API_KEY', settingObject.google_map_distance_matrix_api_key);
                }
                if (('google_map_distance_matrix_api_endpoint' in settingObject) && (settingObject.google_map_distance_matrix_api_endpoint.type === 'google_map')) {
                    commit('SET_SETTING_GOOGLE_MAP_DISTANCE_MATRIX_API_ENDPOINT', settingObject.google_map_distance_matrix_api_endpoint);
                }

                //Vendor setting
                if (('vendor_code_prefix' in settingObject) && (settingObject.vendor_code_prefix.type === 'vendor')) {
                    commit('SET_SETTING_VENDOR_CODE_PREFIX', settingObject.vendor_code_prefix);
                }

                if (('vendor_code_suffix' in settingObject) && (settingObject.vendor_code_suffix.type === 'vendor')) {
                    commit('SET_SETTING_VENDOR_CODE_SUFFIX', settingObject.vendor_code_suffix);
                }

                //franchisee setting
                if (('franchisee_code_prefix' in settingObject) && (settingObject.franchisee_code_prefix.type === 'franchisee')) {
                    commit('SET_SETTING_FRANCHISEE_CODE_PREFIX', settingObject.franchisee_code_prefix);
                }

                if (('franchisee_code_suffix' in settingObject) && (settingObject.franchisee_code_suffix.type === 'franchisee')) {
                    commit('SET_SETTING_FRANCHISEE_CODE_SUFFIX', settingObject.franchisee_code_suffix);
                }
                if (('franchisee_payment_reference_prefix' in settingObject) && (settingObject.franchisee_payment_reference_prefix.type === 'franchisee_payment')) {
                    commit('SET_SETTING_FRANCHISEE_PAYMENT_REFERENCE_PREFIX', settingObject.franchisee_payment_reference_prefix);
                }

                if (('franchisee_payment_reference_suffix' in settingObject) && (settingObject.franchisee_payment_reference_suffix.type === 'franchisee_payment')) {
                    commit('SET_SETTING_FRANCHISEE_PAYMENT_REFERENCE_SUFFIX', settingObject.franchisee_payment_reference_suffix);
                }
                if (('order_job_status' in settingObject) && (settingObject.order_job_status.type === 'order')) {
                    commit('SET_SETTING_ORDER_JOB_STATUS', settingObject.order_job_status);
                }
                // simplify payment gateway 3ds status
                if (('payment_simplify_gateway_3ds_status' in settingObject) && (settingObject.payment_simplify_gateway_3ds_status.type === 'payment')) {
                    commit('SET_SETTING_PAYMENT_SIMPLIFY_GATEWAY_3DS_STATUS', settingObject.payment_simplify_gateway_3ds_status);
                }

                // default sms template list
                if(('default_sms_template_list' in settingObject) && (settingObject.default_sms_template_list.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_SMS_TEMPLATE_LIST', settingObject.default_sms_template_list);
                }

                // default appointment payment collection stage template
                if(('default_appointment_payment_collection_stage_template' in settingObject) && (settingObject.default_appointment_payment_collection_stage_template.type === 'default')) {
                    commit('SET_SETTING_DEFAULT_APPOINTMENT_PAYMENT_COLLECTION_STAGE_TEMPLATE', settingObject.default_appointment_payment_collection_stage_template);
                }

                if (('discount_parking_discount_over_coupon_discount' in settingObject) && (settingObject.discount_parking_discount_over_coupon_discount.type === 'discount')) {
                    commit('SET_SETTING_DISCOUNT_PARKING_DISCOUNT_OVER_COUPON_DISCOUNT', settingObject.discount_parking_discount_over_coupon_discount);
                }

                if (('discount_online_appointment_discount_over_coupon_discount' in settingObject) && (settingObject.discount_online_appointment_discount_over_coupon_discount.type === 'discount')) {
                    commit('SET_SETTING_DISCOUNT_ONLINE_APPOINTMENT_DISCOUNT_OVER_COUPON_DISCOUNT', settingObject.discount_online_appointment_discount_over_coupon_discount);
                }
                if (('coupon_weekend_days' in settingObject) && (settingObject.coupon_weekend_days.type === 'coupon')) {
                    commit('SET_SETTING_COUPON_WEEKEND_DAYS', settingObject.coupon_weekend_days);
                }
            });
        },
    },
};
