import {createStore} from 'vuex';
import appAdmins from '@/store/app/admins/admins';
import appSuperAdmins from '@/store/app/superAdmins/superAdmins';
import appAddresses from '@/store/app/addresses/addresses';
import appAuthLogin from '@/store/app/auth/login';
import appAuthLogout from '@/store/app/auth/logout';
import appBusinesses from '@/store/app/businesses/businesses';
import appCustomers from '@/store/app/customers/customers';
import appSettings from '@/store/app/settings/settings';
import appUsers from '@/store/app/users/users';
import appEmployees from '@/store/app/employees/employees';
import appTechnicians from '@/store/app/technicians/technicians';
import appTechnicianAppointments from '@/store/app/technicianAppointments/technicianAppointments'
import appRoles from '@/store/app/roles/roles';
import appPermissions from '@/store/app/permissions/permissions';
import appBankAccounts from '@/store/app/bankAccounts/bankAccounts';
import appTeamLeads from '@/store/app/teamLeads/teamLeads';
import appOnSiteTechnicians from '@/store/app/onSiteTechnicians/onSiteTechnicians';
import appRemoteTechnicians from "@/store/app/remoteTechnicians/remoteTechnicians";
import appServiceCategories from '@/store/app/serviceCategories/serviceCategories';
import appService from '@/store/app/service/service';
import appExpenses from "@/store/app/expenses/expenses";
import appExpenseCategories from "@/store/app/expenses/expenseCategories";
import appExpenseTypes from "@/store/app/expenses/expenseTypes";
import appWarehouses from "@/store/app/warehouses/warehouses";
import appStoreManagers from "@/store/app/storeManagers/storeManagers";
import appProductCategories from "@/store/app/productCategories/productCategories";
import appProducts from "@/store/app/products/products";
import appProductItems from "@/store/app/productItems/productItems"
import appAppointments from "@/store/app/appointments/appointments";
import appOrders from "@/store/app/orders/order";
import appProductItemTracking from "@/store/app/productItemTracking/productItemTracking";
import appProductItemTransaction from "@/store/app/productItemTransactions/productItemTransactions";
import appCashPayments from "@/store/app/cashPayments/cashPayments";
import appCardPayments from "@/store/app/cardPayments/cardPayments";
import appEftPayments from "@/store/app/eftPayments/eftPayments";
import appCoupons from "@/store/app/coupons/coupons";
import appManagers from "@/store/app/managers/managers";
import appCouponUsages from "@/store/app/couponUsages/couponUsages";
import appPaymentGateways from "@/store/app/paymentGateways/paymentGateways";
import appPayments from "@/store/app/payments/payments";
import appProductPrices from "@/store/app/productPrices/productPrices";
import appOrderNotes from "@/store/app/orderNotes/orderNotes";
import appOrderCharges from "@/store/app/orderCharges/orderCharges";
import appOrderCreators from "@/store/app/orderCreators/orderCreators";
import appOrderPayments from "@/store/app/orderPayments/orderPayments";
import appOrderProductItems from "@/store/app/orderProductItems/orderProductItems";
import appLabUsers from "@/store/app/labUsers/labUsers";
import appUserAdditionalInfos from "@/store/app/userAdditionalInfos/userAdditionalInfos";
import appAppointmentConsents from "@/store/app/appointmentConsents/appointmentConsents";
import appCouponUser from "@/store/app/couponUser/couponUser";
import appRefunds from "@/store/app/refunds/refunds";
import appVendors from "@/store/app/vendors/vendors";
import appProductLicenses from "@/store/app/productLicenses/productLicenses";
import appRefundNotes from "@/store/app/refundNotes/refundNotes";
import appCreditedPayments from "@/store/app/creditedPayments/creditedPayments";
import appAppointmentHistories from "@/store/app/appointmentHistories/appointmentHistories";
import appTechnicianCommissionPayments from "@/store/app/technicianCommissionPayments/technicianCommissionPayments";
import appTechnicianExtraCommissions from "@/store/app/technicianExtraCommissions/technicianExtraCommissions";
import appLeaveApplications from "@/store/app/leaveApplications/leaveApplications";
import appServiceTips from '@/store/app/serviceTips/serviceTips';
import appForgotPassword from '@/store/app/auth/forgotPassword';
import appForgotPasswordVerifyCode from '@/store/app/auth/forgotPasswordVerifyCode';
import appResetPassword from '@/store/app/auth/resetPassword';
import appContacts from '@/store/app/contacts/contacts';
import appCustomerOpinions from '@/store/app/customerOpinions/customerOpinions';
import appVacancies from '@/store/app/vacancies/vacancies'
import appCustomerCares from '@/store/app/customerCares/customerCares'
import appFranchisees from '@/store/app/franchisees/franchisees'
import appDiscounts from '@/store/app/discounts/discounts'
import appEmailTemplates from '@/store/app/emailTemplates/emailTemplates'
import appSmsTemplates from '@/store/app/smsTemplates/smsTemplates'
import appBlogCategories from '@/store/app/blogCategories/blogCategories'
import appBlogTags from '@/store/app/blogTags/blogTags'
import appBlogs from '@/store/app/blogs/blogs'
import appScams from '@/store/app/scams/scams'
import appBlogBlogTags from '@/store/app/blogBlogTags/blogBlogTags'
import appFranchiseeTechnicians from '@/store/app/franchiseeTechnicians/franchiseeTechnicians'
import appOrderProcessors from '@/store/app/orderProcessors/orderProcessors'
import appFranchiseePayments from '@/store/app/franchiseePayments/franchiseePayments'
import appEventCalendars from '@/store/app/eventCalendars/eventCalendars'
import appAfterpayPayments  from '@/store/app/afterpayPayments/afterpayPayments'
import appAfterpayPaymentGateways from '@/store/app/afterpayPaymentGateways/afterpayPaymentGateways'
import appCallRequests from '@/store/app/callRequests/callRequests'
import appCallRequestMessages from '@/store/app/callRequestMessages/callRequestMessages'
import appDashboards from '@/store/app/dashboards/dashboards'
import appAppointmentNotes from '@/store/app/appointmentNotes/appointmentNotes';
import appLoyaltyPoints   from "@/store/app/loyaltyPoints/loyaltyPoints";
import appAppointmentSchedules   from "@/store/app/appointmentSchedules/appointmentSchedules";
import smsMarketings from "@/store/app/smsMarketings/smsMarketings";
import appOrderJobs from "@/store/app/orderJobs/orderJobs";
import appOrderJobNotes from "@/store/app/orderJobNotes/orderJobNotes";
import appPartners from "@/store/app/partners/partners";
import appAdminTasks from "@/store/app/adminTasks/adminTasks";
import appAdminTaskHistories from "@/store/app/adminTaskHistories/adminTaskHistories";
import appPoolOfTechnicians from "@/store/app/poolOfTechnicians/poolOfTechnicians";
import appCustomerFeedbacks from "@/store/app/customerFeedbacks/customerFeedbacks";
import appScamCategories from "@/store/app/scamCategories/scamCategories";
import appTags from "@/store/app/tags/tags";
import appFollowUps from "@/store/app/followUps/followUps";
import appShortTextTemplates from "@/store/app/shortTextTemplates/shortTextTemplates";
import appAppointmentPaymentCollections from "@/store/app/appointmentPaymentCollections/appointmentPaymentCollections";
import appReferrals from "@/store/app/referrals/referrals";
import appJobTransfers from "@/store/app/jobTransfers/jobTransfers";
import appAppointmentInteractions from '@/store/app/appointmentInteractions/appointmentInteractions';

export default createStore({
    state: {
        previousRoute: {},

        notFound: false,

        roleTypes: [
            {
                value: 0,
                name: "Customer",
            },
            {
                value: 1,
                name: "Admin",
            },
            {
                value: 2,
                name: "Technician",
            },
            {
                value: 3,
                name: "Manager",
            },
            {
                value: 4,
                name: "Lab User",
            },
            {
                value: 5,
                name: "Customer Care",
            },
            {
                value: 6,
                name: "Vendor",
            },
            {
                value: 7,
                name: "Franchisee",
            },
            {
                value: 8,
                name: "Partner",
            },
            {
                value: 9,
                name: "Pool Of Geeks",
            },
            {
                value: 10,
                name: "Referral",
            }
        ],
        timeIntervalOptions: [
            {
            name: '1 Hour 00 min',
            value: "01:00"
            }, 
            {
            name: '1 Hour 30 min',
            value: "01:30"
            }, 
            {
            name: '2 Hour 00 min',
            value: "02:00"
            }, 
            {
            name: '2 Hour 30 min',
            value: "02:30"
            }, 
            {
            name: '3 Hour 00 min',
            value: "03:00"
            }, 
            {
            name: '3 Hour 30 min',
            value: "03:30"
            }, 
            {
            name: '4 Hour 00 min',
            value: "04:00"
            }, 
            {
            name: '4 Hour 30 min',
            value: "04:30"
            }, 
            {
            name: '5 Hour 00 min',
            value: "05:00"
            }, 
            {
            name: '5 Hour 30 min',
            value: "05:30"
            }, 
            {
            name: '6 Hour 00 min',
            value: "06:00"
            }, 
        ],
    },

    getters: {
        previousRoute(state) {
            return state.previousRoute;
        },

        notFound(state) {
            return state.notFound;
        },

        roleTypes(state){
            return state.roleTypes;
        },
        timeIntervalOptions(state){
            return state.timeIntervalOptions;
        }
    },
    mutations: {
        SET_PREVIOUS_ROUTE(state, route) {
            state.previousRoute = route;
        },

        SET_NOTFOUND_ROUTE_STATUS(state, status) {
            state.notFound = status;
        },
    },
    actions: {

        commitNotFoundRouteStatus({commit}, status) {
            commit('SET_NOTFOUND_ROUTE_STATUS', status);
        },
    },
    modules: {
        appAdmins,
        appSuperAdmins,
        appAddresses,
        appAuthLogin,
        appAuthLogout,
        appBusinesses,
        appCustomers,
        appSettings,
        appUsers,
        appEmployees,
        appTechnicians,
        appTechnicianAppointments,
        appRoles,
        appBankAccounts,
        appTeamLeads,
        appOnSiteTechnicians,
        appRemoteTechnicians,
        appServiceCategories,
        appExpenses,
        appExpenseCategories,
        appExpenseTypes,
        appWarehouses,
        appStoreManagers,
        appService,
        appPermissions,
        appProductCategories,
        appProducts,
        appProductItems,
        appOrders,
        appProductItemTracking,
        appProductItemTransaction,
        appCashPayments,
        appCardPayments,
        appEftPayments,
        appCoupons,
        appAppointments,
        appPaymentGateways,
        appPayments,
        appManagers,
        appCouponUsages,
        appProductPrices,
        appOrderNotes,
        appOrderCharges,
        appOrderCreators,
        appOrderPayments,
        appOrderProductItems,
        appLabUsers,
        appUserAdditionalInfos,
        appAppointmentConsents,
        appCouponUser,
        appRefunds,
        appVendors,
        appProductLicenses,
        appRefundNotes,
        appCreditedPayments,
        appAppointmentHistories,
        appTechnicianCommissionPayments,
        appTechnicianExtraCommissions,
        appLeaveApplications,
        appServiceTips,
        appForgotPassword,
        appForgotPasswordVerifyCode,
        appResetPassword,
        appContacts,
        appCustomerOpinions,
        appVacancies,
        appCustomerCares,
        appFranchisees,
        appDiscounts,
        appEmailTemplates,
        appSmsTemplates,
        appBlogCategories,
        appBlogTags,
        appBlogs,
        appBlogBlogTags,
        appFranchiseeTechnicians,
        appOrderProcessors,
        appFranchiseePayments,
        appEventCalendars,
        appAfterpayPayments,
        appAfterpayPaymentGateways,
        appCallRequests,
        appCallRequestMessages,
        appDashboards,
        appAppointmentNotes,
        appLoyaltyPoints,
        appAppointmentSchedules,
        smsMarketings,
        appOrderJobs,
        appOrderJobNotes,
        appPartners,
        appAdminTasks,
        appAdminTaskHistories,
        appPoolOfTechnicians,
        appCustomerFeedbacks,
        appScamCategories,
        appScams,
        appTags,
        appFollowUps,
        appShortTextTemplates,
        appAppointmentPaymentCollections,
        appReferrals,
        appJobTransfers,
        appAppointmentInteractions,
    },
});
